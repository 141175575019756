import { useDispatch, useSelector } from '@/store';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalTabs from '@components/ModalTabs';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton } from '@mui/material';
import { Suspense, useEffect, useState, type FC } from 'react';
import { setUpdateDeviceConfigurationModal } from '../slices/deviceConfigurationSlice';
import { Tab } from '../utils/tabs';
import DeviceModalTitle from './DeviceModalTitle';
import AccessTab from './accessTab/AccessTab';
import GeneralTab from './generalTab/GeneralTab';

interface ConfirmationModal {
  title: string;
  content: string;
  action: () => void;
  actionButtonText: string;
}

interface Props {
  deviceId: string;
  onClose: () => void;
}

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { _ } = useLingui();
  const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationModal | null>(null);
  const [dirtyForm, setDirtyForm] = useState(false);

  // Prevent user from leaving the page if the form is dirty
  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      if (dirtyForm) event.preventDefault();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dirtyForm]);

  const handleChangeTab = (changeTabAction: () => void) => {
    if (dirtyForm) {
      setConfirmationDialog({
        title: _(msg`¿Estás seguro que deseas cambiar de pestaña?`),
        content: _(msg`Los cambios que hayas realizado no se guardarán.`),
        action: () => {
          changeTabAction();
          setDirtyForm(false);
          setConfirmationDialog(null);
        },
        actionButtonText: _(msg`Cambiar de pestaña`),
      });
    } else {
      changeTabAction();
    }
  };

  return (
    <>
      <Dialog open={confirmationDialog !== null}>
        <DialogTitle>{confirmationDialog?.title || ''}</DialogTitle>
        <DialogContent dividers>{confirmationDialog?.content || ''}</DialogContent>
        <DialogActions>
          <Button variant="outlined" color="info" onClick={() => setConfirmationDialog(null)}>
            <Trans>Volver al formulario</Trans>
          </Button>
          <Button variant="contained" color="error" onClick={confirmationDialog?.action}>
            {confirmationDialog?.actionButtonText || ''}
          </Button>
        </DialogActions>
      </Dialog>
      <DeviceModalTitle deviceId={deviceId} onClose={onClose} />
      <ModalTabs
        changeTabMiddleware={handleChangeTab}
        // value={tab}
        // setValue={setTab}
        panels={[
          {
            key: Tab.General,
            label: _(msg`General`),
            component: (
              <GeneralTab deviceId={deviceId} handleClose={onClose} setDirtyForm={setDirtyForm} />
            ),
          },
          {
            key: Tab.Access,
            label: _(msg`Accesos`),
            component: (
              <AccessTab deviceId={deviceId} handleClose={onClose} setDirtyForm={setDirtyForm} />
            ),
          },
        ]}
      />
    </>
  );
};

const Fallback: FC<Props> = ({ deviceId, onClose }) => {
  return (
    <>
      <DeviceModalTitle deviceId={deviceId} onClose={onClose} />
      <DialogContent>
        <Skeleton height={300} variant="rounded" />
      </DialogContent>
    </>
  );
};

const DeviceConfigurationModal: FC = () => {
  const dispatch = useDispatch();
  const { updateDeviceConfigurationModal: modalState } = useSelector(
    (state) => state.device_configuration_store,
  );

  const onClose = () => {
    dispatch(setUpdateDeviceConfigurationModal({ open: false }));
  };

  return (
    <Dialog maxWidth="xl" fullWidth open={modalState.open}>
      <ApolloErrorBoundary>
        <Suspense
          fallback={modalState.open && <Fallback {...{ deviceId: modalState.deviceId, onClose }} />}
        >
          {modalState.open && <Component deviceId={modalState.deviceId} onClose={onClose} />}
        </Suspense>
      </ApolloErrorBoundary>
    </Dialog>
  );
};

export default DeviceConfigurationModal;

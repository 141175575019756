import {
  DeviceFiltersOutlier,
  GetDeviceFiltersQuery,
  UpdateDeviceFiltersInput,
} from '@/__generated__/graphql';
import { setSnackbar } from '@/slices/components';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import Loading from '@components/Loading';
import NumericTextField from '@components/NumericTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { AddCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { Fragment, Suspense, useCallback, useEffect, type FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import UPDATE_DEVICE_FILTERS from '../graphql/mutations/updateDeviceFilters';
import GET_DEVICE_FILTERS from '../graphql/queries/getDeviceFilters';
import { schema } from '../utils/validationSchema';
import ComingSoon from './ComingSoon';

interface Props {
  deviceId: string;
  handleClose: () => void;
  setDirtyForm: (value: boolean) => void;
}

const FiltersTab: FC<Props> = (props) => {
  return (
    <ApolloErrorBoundary>
      <Suspense fallback={<Loading />}>
        <FormComponent {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

const formSelectOptions = {
  topicVersion: [
    {
      label: (
        <em>
          <Trans>Sin asignar</Trans>
        </em>
      ),
      value: '',
    },
    { label: <Trans>Versión 1</Trans>, value: 'v1' },
    { label: <Trans>Versión 2</Trans>, value: 'v2' },
  ],
  outliersFilterVersion: [
    {
      label: (
        <em>
          <Trans>Sin asignar</Trans>
        </em>
      ),
      value: '',
    },
    { label: <Trans>Versión 1</Trans>, value: 'v1' },
    { label: <Trans>Versión 2</Trans>, value: 'v2' },
    { label: <Trans>Versión 3</Trans>, value: 'v3' },
  ],
};

export type OutlierDataType = 'radar' | 'dp' | 'wh' | 'wf';
type OutlierName = 'h' | 's' | 'f';

type FormValues = {
  deviceId: string;
  topicVersion: 'v1' | 'v2' | '';
  filters: {
    outOfRangeVelocity: boolean;
    outliers: {
      version: 'v1' | 'v2' | 'v3' | '';
      list: {
        dataType: OutlierDataType;
        name: OutlierName;
        windowSize: number;
        sdThreshold: number;
        smoothing: boolean;
      }[];
    };
  };
};

/**
 * Used to transform the data returned from query GET_DEVICE_FILTERS
 * into FormValues to use as defaultValues for the form.
 */
const transformApiDataToFormValues = (
  deviceId: string,
  data?: GetDeviceFiltersQuery['deviceDataConfiguration'],
): FormValues => {
  if (!data)
    return {
      deviceId,
      topicVersion: '',
      filters: {
        outOfRangeVelocity: false,
        outliers: {
          version: '',
          list: [],
        },
      },
    };

  const list: FormValues['filters']['outliers']['list'] = [];

  for (const dataType of ['radar', 'dp', 'wh', 'wf'] as OutlierDataType[]) {
    (data.filters?.outliers[dataType] ?? []).forEach((outlier) => {
      list.push({
        dataType,
        name: outlier.name as OutlierName,
        windowSize: outlier.windowSize,
        sdThreshold: outlier.sdThreshold,
        smoothing: outlier.smoothing,
      });
    });
  }

  return {
    deviceId,
    topicVersion: (data.topicVersion as 'v1' | 'v2' | null) ?? '',
    filters: {
      outOfRangeVelocity: data.filters?.outOfRangeVelocity ?? false,
      outliers: {
        version: (data.filters?.outliers.version as 'v1' | 'v2' | 'v3' | null) ?? '',
        list,
      },
    },
  };
};

/**
 * Used to transform the data from the form to the format
 * required by the mutation UPDATE_DEVICE_FILTERS
 */
const transformFormValuesToApiData = (values: FormValues): UpdateDeviceFiltersInput => {
  const outliers: Record<OutlierDataType, DeviceFiltersOutlier[]> = {
    radar: [],
    dp: [],
    wh: [],
    wf: [],
  };
  values.filters.outliers.list.forEach((outlier) => {
    outliers[outlier.dataType].push({
      name: outlier.name,
      windowSize: outlier.windowSize,
      sdThreshold: outlier.sdThreshold,
      smoothing: outlier.smoothing,
    });
  });
  return {
    deviceId: values.deviceId,
    topicVersion: values.topicVersion || null,
    filters: {
      outOfRangeVelocity: values.filters.outOfRangeVelocity,
      outliers: {
        version: values.filters.outliers.version || null,
        wh: outliers.wh,
        wf: outliers.wf,
        radar: outliers.radar,
        dp: outliers.dp,
      },
    },
  };
};

const FormComponent: FC<Props> = ({ deviceId, handleClose, setDirtyForm }) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const { data } = useSuspenseQuery(GET_DEVICE_FILTERS, { variables: { deviceId } });

  const defaultValues = transformApiDataToFormValues(deviceId, data?.deviceDataConfiguration);

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver<FormValues>(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'filters.outliers.list',
  });

  const [update, { loading: updateLoading }] = useMutation(UPDATE_DEVICE_FILTERS, {
    onCompleted: ({ updateDeviceFilters }) => {
      methods.reset(transformApiDataToFormValues(deviceId, updateDeviceFilters));
      dispatch(
        setSnackbar({
          open: true,
          message: _(msg`Dato actualizado correctamente`),
          severity: 'success',
        }),
      );
    },
    onError: (errors) => {
      dispatch(
        setSnackbar({
          open: true,
          message: `${_(msg`Error al actualizar el dato`)}: ${errors.message}`,
          severity: 'error',
        }),
      );
    },
  });

  const { isDirty, isSubmitting, errors } = methods.formState;

  useEffect(() => setDirtyForm(isDirty), [isDirty, setDirtyForm]);

  const submitHandler = async (formData: FormValues) => {
    update({
      variables: {
        input: transformFormValuesToApiData(formData),
      },
    });
  };

  const outliersList = methods.watch('filters.outliers.list');

  /**
   * Dynamic select options for the field `dataType`.
   * The select option will appear on the list if there is still a `name` option available
   */
  const getDataTypeSelectList = (index: number) => {
    const usedNames = {
      radar: new Set<OutlierName>(),
      dp: new Set<OutlierName>(),
      wh: new Set<OutlierName>(),
      wf: new Set<OutlierName>(),
    };

    outliersList.forEach((field, i) => {
      if (i === index) return;
      usedNames[field.dataType].add(field.name);
    });

    const dataTypesAvailables: { label: string; value: OutlierDataType }[] = [];

    for (const dataType of ['radar', 'dp', 'wh', 'wf'] as OutlierDataType[]) {
      if (usedNames[dataType].size < 3) {
        dataTypesAvailables.push({ label: dataType, value: dataType });
      }
    }

    return dataTypesAvailables;
  };

  /**
   * Dynamic select options for the field `name`.
   * The select option will appear on the list if there is still a `name` available for the `dataType`
   * selected on the same field.
   */
  const getNameSelectList = useCallback(
    (index: number, dataType?: OutlierDataType) => {
      const selectedDatatype = dataType ?? outliersList[index].dataType;
      const usedNames = new Set<OutlierName>();

      outliersList.forEach((field, i) => {
        if (i === index) return;
        if (field.dataType === selectedDatatype) usedNames.add(field.name);
      });
      const namesAvailables: { label: string; value: OutlierName }[] = [];
      for (const name of ['h', 's', 'f'] as OutlierName[]) {
        if (!usedNames.has(name)) {
          namesAvailables.push({ label: name, value: name });
        }
      }
      return namesAvailables;
    },
    [outliersList],
  );

  const getNewFilterDefaultValue = (): FormValues['filters']['outliers']['list'][number] => {
    const dataType = getDataTypeSelectList(outliersList.length)[0].value;
    return {
      dataType,
      name: getNameSelectList(outliersList.length, dataType)[0].value,
      windowSize: 10,
      sdThreshold: 3,
      smoothing: false,
    };
  };

  useEffect(() => {
    // change the default value of the field `name` when the field `dataType` changes
    // to avoid select the same `name` twice
    const subscription = methods.watch((value, { name, type }) => {
      if (type === 'change' && name?.startsWith('filters.outliers.list')) {
        const index = Number(name.split('.')[3]);
        const selectList = getNameSelectList(
          index,
          value.filters?.outliers?.list?.[index]?.dataType,
        );
        if (
          !selectList.filter(
            (option) => option.value === value.filters?.outliers?.list?.[index]?.name,
          ).length
        )
          methods.setValue(`filters.outliers.list.${index}.name`, selectList[0].value);
      }
    });
    return () => subscription.unsubscribe();
  }, [getNameSelectList, methods, outliersList]);

  return (
    <form onSubmit={methods.handleSubmit(submitHandler)}>
      <DialogContent
        sx={{
          maxWidth: 'md',
          margin: 'auto',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <Typography variant="h5" mb={2}>
          <Trans>Filtros</Trans>
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" fontSize="small" gap={2} mb={3}>
          <Controller
            name="topicVersion"
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth size="small">
                <InputLabel htmlFor="topic-version">
                  <Trans>Versión del Tópico</Trans>
                </InputLabel>
                <Select
                  {...field}
                  error={!!error}
                  label={_(msg`Versión del Tópico`)}
                  inputProps={{ id: 'topic-version' }}
                >
                  {formSelectOptions.topicVersion.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {error && <FormHelperText error={!!error}>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Box>
        <Box
          display="grid"
          gap={2}
          gridTemplateColumns="1fr auto 1fr"
          minHeight={500}
          alignItems="start"
        >
          <Box>
            <Typography variant="h6" mb={2}>
              <Trans>Configuración de Filtros Activos</Trans>
            </Typography>
            <Controller
              name="filters.outOfRangeVelocity"
              control={methods.control}
              render={({ field: { value, ...rest } }) => (
                <FormControlLabel
                  control={<Switch {...rest} checked={value} />}
                  label={_(msg`Filtro de Velocidad Fuera de Rango`)}
                />
              )}
            />
            <Controller
              name="filters.outliers.version"
              control={methods.control}
              render={({ field, fieldState: { error } }) => (
                <FormControl sx={{ mt: 1.5 }} fullWidth size="small">
                  <InputLabel htmlFor="outliers-version">
                    <Trans>Versión del Filtro de Outliers</Trans>
                  </InputLabel>
                  <Select
                    {...field}
                    error={!!error}
                    label={_(msg`Versión del Filtro de Outliers`)}
                    inputProps={{ id: 'outliers-version' }}
                  >
                    {formSelectOptions.outliersFilterVersion.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && <FormHelperText error={!!error}>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
            <Typography variant="h6" mt={3} mb={2}>
              <Trans>Listado de Filtros</Trans>
              {errors.filters?.outliers?.list?.root && (
                <FormHelperText error={!!errors.filters.outliers.list.root}>
                  {errors.filters.outliers.list.root.message}
                </FormHelperText>
              )}
            </Typography>
            <Box py={1} maxHeight={275} sx={{ overflowY: 'auto' }} className="custom-scrollbar">
              {fields.length === 0 && (
                <Typography variant="subtitle2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                  <Trans>No se han configurado filtros.</Trans>
                </Typography>
              )}
              {fields.map((field, index) => (
                <Fragment key={field.id}>
                  <Box width="100%" display="grid" gap={2} gridTemplateColumns="1fr 1fr 1fr">
                    <Controller
                      name={`filters.outliers.list.${index}.dataType`}
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small">
                          <InputLabel htmlFor={`filters-${index}-message_type`}>
                            <Trans>Tipo de mensaje</Trans>
                          </InputLabel>
                          <Select
                            {...field}
                            label={_(msg`Tipo de mensaje`)}
                            inputProps={{ id: `filters-${index}-message_type` }}
                          >
                            {getDataTypeSelectList(index).map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name={`filters.outliers.list.${index}.name`}
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small">
                          <InputLabel htmlFor={`filters-${index}-variable`}>
                            <Trans>Variable</Trans>
                          </InputLabel>
                          <Select
                            {...field}
                            label={_(msg`Variable`)}
                            inputProps={{ id: `filters-${index}-variable` }}
                          >
                            {getNameSelectList(index).map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                    <Button variant="outlined" color="error" onClick={() => remove(index)}>
                      <Trans>Eliminar</Trans>
                    </Button>
                    <Controller
                      name={`filters.outliers.list.${index}.windowSize`}
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <NumericTextField
                          {...field}
                          size="small"
                          label={_(msg`Tamaño de ventana`)}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`filters.outliers.list.${index}.sdThreshold`}
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <NumericTextField
                          {...field}
                          size="small"
                          label={_(msg`SD Threshold`)}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`filters.outliers.list.${index}.smoothing`}
                      control={methods.control}
                      render={({ field: { value, ...rest } }) => (
                        <FormControlLabel
                          control={<Checkbox size="small" {...rest} checked={value} />}
                          label={_(msg`Alisado`)}
                        />
                      )}
                    />
                  </Box>
                  {index !== fields.length - 1 && <Divider sx={{ my: 2 }} />}
                </Fragment>
              ))}
              <Button
                sx={{ my: 2 }}
                onClick={() => append(getNewFilterDefaultValue())}
                size="medium"
                disabled={getDataTypeSelectList(outliersList.length).length === 0}
                startIcon={<AddCircle />}
              >
                <Trans>Agregar filtro</Trans>
              </Button>
            </Box>
          </Box>
          <Divider orientation="vertical" />
          <Box position="relative" overflow="hidden" height="100%">
            <Typography variant="h6" mb={2}>
              <Trans>Visualización de Filtros</Trans>
            </Typography>
            <ComingSoon />
            <Box
              display="grid"
              sx={{
                alignItems: 'end',
                justifyItems: 'center',
                mt: 40,
                zIndex: 1,
                position: 'relative',
              }}
            >
              {/* <AutoAwesome color="warning" sx={{ fontSize: 128, mb: 4 }} /> */}
              <Typography variant="h3">
                <Trans>Próximamente</Trans>
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ maxWidth: 'md', margin: 'auto', py: 2 }}>
        <Button
          disabled={!isDirty}
          color="info"
          variant="outlined"
          onClick={() => methods.reset(defaultValues)}
        >
          <Trans>Restablecer Formulario</Trans>
        </Button>
        <Box flexGrow={1} />
        <Button color="info" variant="outlined" onClick={() => handleClose()}>
          <Trans>Cerrar</Trans>
        </Button>
        <LoadingButton
          disabled={!isDirty}
          loading={isSubmitting || updateLoading}
          type="submit"
          variant="contained"
        >
          <Trans>Guardar</Trans>
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default FiltersTab;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type ModalStateRequiredDevice =
  | {
      open: true;
      deviceId: string;
    }
  | {
      open: false;
    };

interface DeviceConfigurationState {
  updateDeviceConfigurationModal: ModalStateRequiredDevice;
  updateDeviceAliasesModal: ModalStateRequiredDevice;
  deleteDeviceModal: ModalStateRequiredDevice;
  cloneDeviceModal: ModalStateRequiredDevice;
}

const initialState: DeviceConfigurationState = {
  updateDeviceConfigurationModal: {
    open: false,
  },
  updateDeviceAliasesModal: {
    open: false,
  },
  deleteDeviceModal: {
    open: false,
  },
  cloneDeviceModal: {
    open: false,
  },
};

const { reducer, actions } = createSlice({
  name: 'device_configuration_store',
  initialState,
  reducers: {
    setUpdateDeviceConfigurationModal(
      state: DeviceConfigurationState,
      action: PayloadAction<DeviceConfigurationState['updateDeviceConfigurationModal']>,
    ) {
      state.updateDeviceConfigurationModal = action.payload;
    },
    setUpdateDeviceAliasesModal(
      state: DeviceConfigurationState,
      action: PayloadAction<DeviceConfigurationState['updateDeviceAliasesModal']>,
    ) {
      state.updateDeviceAliasesModal = action.payload;
    },
    setDeleteDeviceModal(
      state: DeviceConfigurationState,
      action: PayloadAction<DeviceConfigurationState['deleteDeviceModal']>,
    ) {
      state.deleteDeviceModal = action.payload;
    },
    setCloneDeviceModal(
      state: DeviceConfigurationState,
      action: PayloadAction<DeviceConfigurationState['cloneDeviceModal']>,
    ) {
      state.cloneDeviceModal = action.payload;
    },
  },
});

export default reducer;
export const {
  setUpdateDeviceConfigurationModal,
  setUpdateDeviceAliasesModal,
  setDeleteDeviceModal,
  setCloneDeviceModal,
} = actions;

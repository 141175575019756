import { gql } from '@/__generated__';

const GET_USER_AVATAR = gql(`#graphql
  query GetUserAvatar($userId: Int!) {
    user(input: { id: $userId }) {
      id
      picture
      name
      lastnames
    }
  }
`);

export default GET_USER_AVATAR;

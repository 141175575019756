import { useState, type FC, TransitionStartFunction } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/store';
import ReportProblemDialog from '@features/reportProblem';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import DateTimeRangePicker, {
  RangeTimeValue,
  formatDateTimeRangeLabel,
} from '@features/standardDesign/components/DateTimeRangePicker';
import { setParameters } from '../../slices/devicesAnalysisSlice';
import AlertIcon from '@/icons/amaruNewIcons/AlertIcon';
import { type DevicesAnalysisDevice } from '../DevicesAnalysis';
import { useAnalyticsEventTracker } from '@/gaEvents';
import DeviceOptions from 'src/components/optionsMenu/DeviceOptions';
import DeviceMenu from 'src/features/deviceMenu/components/DeviceMenu';
import GearIcon from 'src/icons/amaruNewIcons/GearIcon';
import GateOptions from 'src/components/optionsMenu/GateOptions';
import { useLingui } from '@lingui/react';
import { msg, Trans } from '@lingui/macro';
import { useFormatters } from 'src/hooks/useFormatters';
import { useQuery } from '@apollo/client';
import GET_ACCESS_DEVICES from 'src/graphql/querys/access/getAccessDevices';

const windowOptions = [
  {
    label: msg`Dato bruto`,
    window: null,
  },
  {
    label: msg`1 minuto`,
    window: '1m',
  },
  {
    label: msg`5 minutos`,
    window: '5m',
  },
  {
    label: msg`10 minutos`,
    window: '10m',
  },
  {
    label: msg`15 minutos`,
    window: '15m',
  },
  {
    label: msg`30 minutos`,
    window: '30m',
  },
  {
    label: msg`1 hora`,
    window: '1h',
  },
  {
    label: msg`6 horas`,
    window: '6h',
  },
  {
    label: msg`12 horas`,
    window: '12h',
  },
  {
    label: msg`1 día`,
    window: '1d',
  },
  {
    label: msg`3 días`,
    window: '3d',
  },
];

interface Props {
  device: DevicesAnalysisDevice;
  startTransition: TransitionStartFunction;
}

const Toolbar: FC<Props> = ({ device, startTransition }) => {
  const { _, i18n } = useLingui();
  const { formatDateTimeRange } = useFormatters();
  const dispatch = useDispatch();
  const { clickEvent, selectorEvent } = useAnalyticsEventTracker('Station Analysis');
  const [showReportDialog, setShowReportDialog] = useState(false);
  const { parameters } = useSelector((state) => state.devices_analysis_store);
  const { data: access } = useQuery(GET_ACCESS_DEVICES);

  const handleReport = () => {
    clickEvent({ label: 'report-problem' });
    setShowReportDialog(true);
  };

  // const handleExpand = () => {
  //   clickEvent({ label: 'expand-chart' });
  // };
  //
  // const handleDownload = () => {
  //   clickEvent({ label: 'download-chart' });
  // };
  //
  const handleChangeWindow = (value: string | null) => {
    selectorEvent({ label: 'window', value: value ?? 'Dato bruto' });
    startTransition(() => {
      dispatch(
        setParameters({
          window: value,
        }),
      );
    });
  };

  const handleChangeTimeRange = (value: RangeTimeValue) => {
    selectorEvent({
      label: 'period',
      value: formatDateTimeRangeLabel(value, i18n, formatDateTimeRange),
    });
    startTransition(() => {
      dispatch(
        setParameters({
          start: value.start,
          stop: value.stop,
        }),
      );
    });
  };

  return (
    <>
      <ReportProblemDialog
        device={{
          id: device.id,
          name: device.profile.name,
          type: device.profile.type,
          organization_name: device?.profile?.organization?.name,
        }}
        show={showReportDialog}
        handleClose={() => setShowReportDialog(false)}
      />
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          sm: 'repeat(2, 1fr) auto',
        }}
        gap={2}
      >
        <DateTimeRangePicker
          value={{
            start: parameters.start,
            stop: parameters.stop,
          }}
          onChange={(value) => {
            handleChangeTimeRange(value);
          }}
          onChangeWindow={(value) => {
            handleChangeWindow(value);
          }}
        />
        <Autocomplete
          size="small"
          options={windowOptions}
          value={windowOptions.find((option) => option.window === parameters.window)}
          getOptionLabel={(option) => _(option.label)}
          onChange={(_, value) => {
            handleChangeWindow(value.window);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={_(
                msg({
                  message: 'Promediar por periodo',
                  comment:
                    'Análisis de estaciones - etiqueta de campo de selección de ventana de promedio',
                }),
              )}
            />
          )}
          disableClearable
        />
        <Box display="flex" gap={1}>
          <Button
            onClick={handleReport}
            color="warning"
            variant="outlined"
            sx={{ justifySelf: 'flex-end' }}
          >
            <Trans comment="Análisis de estaciones - botón de reportar problema">
              Reportar un problema
            </Trans>{' '}
            <AlertIcon sx={{ ml: 2 }} />
          </Button>
          {device.profile.type === 'CFC' ? (
            <GateOptions newIcon buttonSize="medium" gateId={device.id} reloadData={() => {}} />
          ) : (access?.authorization.authorizationGeneral?.showOrganizationFilter
              ? (<DeviceMenu CustomIcon={() => <GearIcon color="info" />} deviceId={device.id} />)
              : (<DeviceOptions
                  deviceId={device.id}
                  CustomIcon={() => <GearIcon color="info" />}
                  reloadData={() => {}} 
                  />)
          )}
        </Box>
      </Box>
    </>
  );
};

export default Toolbar;

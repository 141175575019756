import { gql } from '@/__generated__';

const DELETE_DGA_CONFIGURATION = gql(`#graphql
  mutation DeleteDgaConfiguration($deviceId: ID!) {
    deleteDgaConfiguration(deviceId: $deviceId) {
      id
      dgaConfiguration {
        id
        code
        rut
        password
        type
        frequency
        hour
        weekday
        day
        enabled
      }
    }
  }
`);

export default DELETE_DGA_CONFIGURATION;

import { gql } from '@/__generated__';

const GET_DEVICE_ALIASES = gql(`#graphql
  query DeviceConfiguration_GetDeviceAliases($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
        organizationsAliases {
          alias
          organization {
            id
            name
          }
        }
      }
    }
  }
`);

export default GET_DEVICE_ALIASES;

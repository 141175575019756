import { gql } from '@/__generated__';

const DELETE_DEVICE = gql(`#graphql
  mutation DeviceConfiguration_DeleteDevice($deviceId: ID!, $hardDelete: Boolean) {
    deleteDevice(deviceId: $deviceId, hardDelete: $hardDelete) {
      id
      hardDelete
    }
  }
`);

export default DELETE_DEVICE;

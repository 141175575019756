import { gql } from '@/__generated__';

const UPDATE_DEVICE_USERS_ACCESS = gql(`#graphql
  mutation DeviceConfiguration_UpdateDeviceUsersAccess($deviceId: ID!, $users: [UpdateDeviceUserAccess!]!) {
    updateDeviceUsersAccess(deviceId: $deviceId, users: $users) {
      id
      users(input:{
        ignoreAccess: true
      }) {
        hasAccess
        user {
          id
          email
          fullName
        }
      }
    }
  }
`);

export default UPDATE_DEVICE_USERS_ACCESS;

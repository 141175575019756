import { AlertTypes, MetricUnit } from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { setUpdateDeviceAlertsModal } from '@/slices/modals';
import { useDispatch, useSelector } from '@/store';
import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client';
import { FormInputText } from '@components/form';
import ModalTitle from '@components/modal/ModalTitle';
import StandardAutocompleteIdsWithFallback from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Skeleton,
  TextField,
} from '@mui/material';
import { Suspense, useEffect, useState, type FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DISABLE_DEVICE_ALERTS from '../graphql/mutations/disableDeviceAlerts';
import UPDATE_DEVICE_ALERTS from '../graphql/mutations/updateDeviceAlerts';
import GET_CURRENT_DEVICE_ALERTS from '../graphql/queries/getDeviceAlerts';
import { IFormInput, formSchema } from '../utils/formSchema';
import { LoadingButton } from '@mui/lab';
import ModalActions from 'src/components/modal/ModalActions';

interface Props {
  deviceId?: string;
  onClose: () => void;
}

const Component: FC<Props> = ({ deviceId: preselectedDeviceId, onClose }) => {
  const { _ } = useLingui();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const [showDesactivateConfirmation, setShowDesactivateConfirmation] = useState(false);

  const { control, formState, handleSubmit, watch, reset } = useForm<IFormInput>({
    defaultValues: {
      deviceId: preselectedDeviceId,
      alertType: AlertTypes.Flow,
    },
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const deviceId = watch('deviceId');

  const { data } = useSuspenseQuery(
    GET_CURRENT_DEVICE_ALERTS,
    deviceId
      ? {
          variables: {
            input: { id: deviceId },
            flowUnit: MetricUnit.LiterPerSecond,
            levelUnit: MetricUnit.Centimeter,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    const { lastLevel, lastFlow } = data?.device.alerts ?? {};
    if (lastLevel || lastFlow) {
      reset({
        deviceId: data?.device.id ?? undefined,
        alertType: lastFlow ? AlertTypes.Flow : lastLevel ? AlertTypes.Level : undefined,
        levelMin: lastLevel?.min ?? undefined,
        levelMax: lastLevel?.max ?? undefined,
        levelOverflow: lastLevel?.overflow ?? undefined,
        flowMin: lastFlow?.min ?? undefined,
        flowMax: lastFlow?.max ?? undefined,
        flowOverflow: lastFlow?.overflow ?? undefined,
      });
    } else {
      reset({
        deviceId: data?.device.id ?? undefined,
        alertType: AlertTypes.Flow,
      });
    }
  }, [data]);

  const [executeUpdateDeviceAlerts, { loading: updateLoading }] = useMutation(
    UPDATE_DEVICE_ALERTS,
    {
      onCompleted() {
        openModalSuccessMessage(_(msg`Alertas actualizadas correctamente`));
        handleInternalClose();
      },
      onError(error) {
        openModalErrorMessage(
          _(msg`Hubo un error al actualizar las alertas`) + `: ${error.message}`,
        );
      },
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            __typename: 'Device',
            id: deviceId,
          }),
          fields: {
            alerts(_, { INVALIDATE }) {
              return INVALIDATE;
            },
          },
        });
      },
      onQueryUpdated(observableQuery) {
        if (observableQuery.queryName !== 'StandardAutocompleteData') {
          return observableQuery.refetch();
        }
      },
    },
  );
  const [executeDisableDeviceAlerts, { loading: disableLoading }] = useMutation(
    DISABLE_DEVICE_ALERTS,
    {
      onCompleted() {
        openModalSuccessMessage(_(msg`Alertas desactivadas correctamente`));
        handleInternalClose();
      },
      onError(error) {
        openModalErrorMessage(
          _(msg`Hubo un error al desactivar las alertas`) + `: ${error.message}`,
        );
      },
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            __typename: 'Device',
            id: deviceId,
          }),
          fields: {
            alerts(_, { DELETE }) {
              return DELETE;
            },
          },
        });
      },
      onQueryUpdated(observableQuery) {
        if (observableQuery.queryName !== 'StandardAutocompleteData') {
          return observableQuery.refetch();
        }
      },
    },
  );

  const handleDisableAlerts = () => {
    if (!data?.device.id) return;
    executeDisableDeviceAlerts({ variables: { deviceId: data.device.id } });
  };

  const onSubmit: SubmitHandler<IFormInput> = (formData) => {
    const {
      deviceId,
      alertType,
      levelMin,
      levelMax,
      levelOverflow,
      flowMin,
      flowMax,
      flowOverflow,
    } = formData;

    let min: number;
    let max: number;
    let overflow: number;
    let unit;

    if (alertType === AlertTypes.Level) {
      min = levelMin as number;
      max = levelMax as number;
      overflow = levelOverflow as number;
      unit = 'cm';
    } else if (alertType === AlertTypes.Flow) {
      min = flowMin as number;
      max = flowMax as number;
      overflow = flowOverflow as number;
      unit = 'l/s';
    } else {
      return;
    }

    executeUpdateDeviceAlerts({
      variables: {
        deviceId,
        alertType: alertType,
        min: min,
        max: max,
        overflow: overflow,
        unit: unit,
      },
    });
  };

  const handleInternalClose = () => {
    setShowDesactivateConfirmation(false);
    onClose();
  };

  const alertType = watch('alertType');

  const hasAlerts = data?.device.alerts?.lastLevel != null || data?.device.alerts?.lastFlow != null;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle
          onClose={onClose}
          title={
            hasAlerts ? (
              <Trans>Editar configuración de alertas</Trans>
            ) : (
              <Trans>Crear configuración de alertas</Trans>
            )
          }
        />
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            <Controller
              name="deviceId"
              control={control}
              render={({ field, fieldState }) => (
                <StandardAutocompleteIdsWithFallback
                  {...field}
                  dataType="devices"
                  disabled={preselectedDeviceId != null}
                  onChange={(_, value) => field.onChange(value)}
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name="alertType"
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  options={[AlertTypes.Level, AlertTypes.Flow]}
                  disableClearable
                  onChange={(_, value) => field.onChange(value)}
                  getOptionLabel={(option) =>
                    option === AlertTypes.Level ? _(msg`Nivel`) : _(msg`Caudal`)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={_(msg`Tipo de alerta`)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />

            {alertType === AlertTypes.Level && (
              <>
                <FormInputText
                  name="levelMin"
                  control={control}
                  label={_(msg`Nivel mínimo (cm)`)}
                  type="number"
                />

                <FormInputText
                  name="levelMax"
                  control={control}
                  label={_(msg`Nivel máximo (cm)`)}
                  type="number"
                />

                <FormInputText
                  name="levelOverflow"
                  control={control}
                  label={_(msg`Nivel desborde (cm)`)}
                  type="number"
                />
              </>
            )}

            {alertType === AlertTypes.Flow && (
              <>
                <FormInputText
                  name="flowMin"
                  control={control}
                  label={_(msg`Caudal mínimo (L/s)`)}
                  type="number"
                />

                <FormInputText
                  name="flowMax"
                  control={control}
                  label={_(msg`Caudal máximo (L/s)`)}
                  type="number"
                />

                <FormInputText
                  name="flowOverflow"
                  control={control}
                  label={_(msg`Caudal desborde (L/s)`)}
                  type="number"
                />
              </>
            )}
          </Box>
        </DialogContent>

        <ModalActions
          onClose={onClose}
          onSubmit="submit"
          dirtyForm={formState.isDirty}
          onResetForm={() => reset()}
          submitDisabled={!formState.isValid}
          submitLoading={updateLoading}
          customButtons={[
            {
              label: _(msg`Desactivar alertas`),
              color: 'error',
              onClick: () => setShowDesactivateConfirmation(true),
              disabled: !hasAlerts || disableLoading || updateLoading,
              loading: disableLoading,
              variant: 'contained',
            },
          ]}
        />
      </form>

      <Dialog open={showDesactivateConfirmation}>
        <ModalTitle title={<Trans>Deshabilitar alertas</Trans>} />
        <DialogContent dividers>
          <Trans>
            ¿Está seguro que desea desactivar las alertas del dispositivo{' '}
            {data?.device?.profile.name ?? ''}?
          </Trans>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="info"
            onClick={() => setShowDesactivateConfirmation(false)}
          >
            <Trans>Cancelar</Trans>
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            disabled={updateLoading || disableLoading}
            loading={disableLoading}
            onClick={() => handleDisableAlerts()}
          >
            <Trans>Desactivar</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <>
      <ModalTitle onClose={onClose} title={<Skeleton width={250}></Skeleton>} />
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={2}>
          {Array.from({ length: 5 }).map((_, i) => (
            <Skeleton key={i} variant="rounded" width="100%">
              <TextField />
            </Skeleton>
          ))}
        </Box>
      </DialogContent>
      <ModalActions onClose={onClose} />
    </>
  );
};

const UpdateDeviceAlertsModal: FC = () => {
  const dispatch = useDispatch();
  const { updateDeviceAlertsModal } = useSelector((state) => state.modals_store);

  const onClose = () => {
    dispatch(
      setUpdateDeviceAlertsModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={updateDeviceAlertsModal.open} maxWidth="sm" fullWidth>
      {updateDeviceAlertsModal.open && (
        <Suspense fallback={<Fallback onClose={onClose} />}>
          <Component onClose={onClose} deviceId={updateDeviceAlertsModal.deviceId} />
        </Suspense>
      )}
    </Dialog>
  );
};

export default UpdateDeviceAlertsModal;

import { gql } from '@/__generated__';

const GET_CURRENT_DEVICE_ALERTS = gql(`#graphql
  
query GetCurrentDeviceAlerts($input: DeviceInput!,$levelUnit: MetricUnit, $flowUnit: MetricUnit) {
    device(input: $input) {
      id
      profile {
        id
        name
      }
      alerts {
        id
        lastFlow(unit: $flowUnit) {
          overflow   
          min
          max
        }
        lastLevel(unit: $levelUnit) {
          max
          overflow   
          min
          unit
        }
      }
    }
  }

`);

export default GET_CURRENT_DEVICE_ALERTS;

import { useSuspenseQuery } from '@apollo/client';
import { useEffect, useTransition, type FC, type PropsWithChildren, type ReactNode } from 'react';
import GET_ACCESS_DGA from '@/graphql/querys/access/getAccessDGA';
import { Box, Button } from '@mui/material';
import { Trans } from '@lingui/macro';

export enum Access {
  // modals
  CreateDGAConfigurationModal,
  DeviceConfigurationModal,
  DeviceDataConfigurationModal,
  DeviceAlertsConfigurationModal,
  DeleteDeviceModal,
  CloneDeviceModal,

  // buttons
  DeviceMenu_DeviceConfigurationButton,
  DeviceMenu_DeviceDataConfigurationButton,
  DeviceMenu_DeviceAlertsConfigurationButton,
  DeviceConfigurationModal_DeleteDeviceButton,
  DeviceConfigurationModal_CloneDeviceButton,
}

interface Props extends PropsWithChildren {
  access: Access | null;
  deviceId?: string;
  onClose?: () => void;
  /** Fallback component to show when the user is unauthorized */
  fallback?: ReactNode;
}

const Unauthorized: FC<{ onClose?: () => void }> = ({ onClose }) => {
  return (
    <Box mx="auto" p={3}>
      <img src="https://images.unsplash.com/photo-1622350428710-c5ecfe29265a?q=80&w=300&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
      {onClose && (
        <Box width="100%" textAlign="right">
          <Button onClick={onClose} variant="outlined" color="info">
            <Trans>Cerrar</Trans>
          </Button>
        </Box>
      )}
    </Box>
  );
};

const POLLING_INTERVAL = 5 * 60 * 1000; // 5 minutes

const AuthorizationWrapper: FC<Props> = ({ access, children, deviceId, onClose, fallback }) => {
  const [_, startTransition] = useTransition();
  const { data, refetch } = useSuspenseQuery(GET_ACCESS_DGA);

  useEffect(() => {
    const interval = setInterval(() => {
      startTransition(() => {
        refetch();
      });
    }, POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [refetch, startTransition]);

  const unauthorizedComponent =
    fallback !== undefined ? fallback : <Unauthorized onClose={onClose} />;

  if (access === null) {
    return children;
  }

  if (access === Access.CreateDGAConfigurationModal) {
    if (!data.authorization.authorizationDGA?.enableDevices) {
      return unauthorizedComponent;
    }
  }

  if (
    access === Access.DeviceConfigurationModal ||
    access === Access.DeviceMenu_DeviceConfigurationButton
  ) {
    // return unauthorizedComponent;
  }

  if (
    access === Access.DeleteDeviceModal ||
    access === Access.DeviceConfigurationModal_DeleteDeviceButton
  ) {
    // return unauthorizedComponent;
  }

  if (
    access === Access.CloneDeviceModal ||
    access === Access.DeviceConfigurationModal_CloneDeviceButton
  ) {
    // return unauthorizedComponent;
  }

  return children;
};

export default AuthorizationWrapper;

import {
  CreateDgaConfigurationMutationVariables,
  DayOfWeek,
  DgaFrequency,
} from '@/__generated__/graphql';
import NumericTextField from '@components/NumericTextField';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Autocomplete, Box, FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import { FC } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';

type Props = {
  control: Control<CreateDgaConfigurationMutationVariables>;
};

const DgaScheduleController: FC<Props> = ({ control }) => {
  const { _ } = useLingui();
  const enable = useWatch({
    control,
    name: 'scheduleInput.enable',
    exact: true,
  });
  const frequency = useWatch({
    control,
    name: 'scheduleInput.frequency',
    exact: true,
  });

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={2}>
      <Controller
        control={control}
        name="scheduleInput.enable"
        render={({ field: { value, ref, ...rest } }) => (
          <FormGroup>
            <FormControlLabel
              {...rest}
              checked={value}
              inputRef={ref}
              control={<Switch />}
              label={_(msg`Habilitar envíos DGA`)}
            />
          </FormGroup>
        )}
      />

      {enable && (
        <>
          <Controller
            name="scheduleInput.frequency"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                options={Object.values(DgaFrequency)}
                disableClearable
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(value) => {
                  if (value === DgaFrequency.Daily) return _(msg`Diario`);
                  if (value === DgaFrequency.Weekly) return _(msg`Semanal`);
                  if (value === DgaFrequency.Monthly) return _(msg`Mensual`);
                  if (value === DgaFrequency.Hourly) return _(msg`Cada hora`);
                  return value;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={_(msg`Frecuencia`)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
          {frequency === DgaFrequency.Weekly && (
            <Controller
              name="scheduleInput.weekday"
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  options={[
                    DayOfWeek.Monday,
                    DayOfWeek.Tuesday,
                    DayOfWeek.Wednesday,
                    DayOfWeek.Thursday,
                    DayOfWeek.Friday,
                    DayOfWeek.Saturday,
                    DayOfWeek.Sunday,
                  ]}
                  disableClearable
                  value={field.value || undefined}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(value) => {
                    if (value === DayOfWeek.Monday) return _(msg`Lunes`);
                    if (value === DayOfWeek.Tuesday) return _(msg`Martes`);
                    if (value === DayOfWeek.Wednesday) return _(msg`Miércoles`);
                    if (value === DayOfWeek.Thursday) return _(msg`Jueves`);
                    if (value === DayOfWeek.Friday) return _(msg`Viernes`);
                    if (value === DayOfWeek.Saturday) return _(msg`Sábado`);
                    if (value === DayOfWeek.Sunday) return _(msg`Domingo`);
                    return value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={_(msg`Día de la semana`)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          )}
          {frequency === DgaFrequency.Monthly && (
            <Controller
              control={control}
              name="scheduleInput.day"
              render={({ field, fieldState }) => (
                <NumericTextField
                  {...field}
                  label={_(msg`Día del mes`)}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  inputMode="numeric"
                  value={Number.isNaN(field.value) ? '' : field.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '') field.onChange(NaN);
                    else field.onChange(value);
                  }}
                />
              )}
            />
          )}
          {frequency !== DgaFrequency.Hourly && (
            <Controller
              control={control}
              name="scheduleInput.hour"
              render={({ field, fieldState }) => (
                <NumericTextField
                  {...field}
                  label={_(msg`Hora`)}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  value={Number.isNaN(field.value) ? '' : field.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '') field.onChange(NaN);
                    else field.onChange(value);
                  }}
                />
              )}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default DgaScheduleController;

import { Box, Dialog, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from '@/store';
import { closeDeviceProfileModal } from '../slices/deviceProfileSlice';
import { type FC } from 'react';
import DeviceProfileRecentActivity from './modal/RecentActivity';
import DeviceProfileObservations from './modal/Observations';
import DeviceProfileInfo from './modal/Info';
import { Trans } from '@lingui/macro';
import ButtonLink from 'src/components/ButtonLink';
import { Biotech } from '@mui/icons-material';

const DeviceProfileModal = () => {
  const dispatch = useDispatch();
  const {
    deviceProfileModal: { deviceId, open },
  } = useSelector((state) => state.device_profile_store);

  const handleClose = () => {
    dispatch(closeDeviceProfileModal());
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      {deviceId && <DeviceProfileModalContent deviceId={deviceId} />}
    </Dialog>
  );
};

interface Props {
  deviceId: string;
}

const DeviceProfileModalContent: FC<Props> = ({ deviceId }) => {
  return (
    <Box display="grid" gridTemplateRows="auto 1fr">
      <DeviceProfileInfo deviceId={deviceId} />
      <Box>
        <Divider />
        <Box display="grid" gridTemplateColumns={{ sm: '1fr', md: '1fr auto 1fr' }}>
          <Box p={2} pl={{ xs: 2, md: 4 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={1}>
              <Typography variant="h5">
                <Trans>Observaciones</Trans>
              </Typography>
            </Box>
            <DeviceProfileObservations deviceId={deviceId} />
          </Box>
          <Divider
            sx={{
              display: {
                sm: 'none',
                md: 'block',
              },
            }}
            orientation="vertical"
          />
          <Divider
            sx={{
              display: {
                sm: 'block',
                md: 'none',
              },
            }}
            orientation="horizontal"
          />
          <Box p={2} pr={{ xs: 2, md: 4 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={1}>
              <Typography variant="h5">
                <Trans>Actividad reciente</Trans>
              </Typography>
              <ButtonLink
                target="_blank"
                href={(() => {
                  const params = new URLSearchParams();
                  params.append('entity', `device#${deviceId}`);
                  return `/audit?${params.toString()}`;
                })()}
              >
                <Biotech /> <Trans>Ver más</Trans>
              </ButtonLink>
            </Box>
            <DeviceProfileRecentActivity deviceId={deviceId} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeviceProfileModal;

import { experimentalStyled } from '@mui/material/styles';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNavbar from '@components/layout/DashboardNavbar';
import DashboardSidebar from '@components/layout/DashboardSidebar';
import RootErrorBoundary from '@components/RootErrorBoundary';
import { Helmet } from 'react-helmet-async';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '250px',
  },
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

  return (
    <>
      <Helmet>
        <title>Capta Hydro | Amaru</title>
      </Helmet>
      <DashboardLayoutRoot>
        <DashboardNavbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
        <DashboardSidebar
          onMobileClose={(): void => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              <RootErrorBoundary>
                <Outlet />
              </RootErrorBoundary>
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    </>
  );
};

export default DashboardLayout;

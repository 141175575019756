import GET_ACCESS_DEVICE_DATA_CONFIGURATION from '@/graphql/querys/access/getAccessDeviceDataConfiguration';
import { setUpdateDeviceDataConfigurationModal } from '@/slices/modals';
import { useSelector } from '@/store';
import { useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import AuthorizationWrapper, { Access } from '@components/AuthorizationWrapper';
import ModalTabs from '@components/ModalTabs';
import CalibrationTab from '@features/deviceSettingsCalibration/components/CalibrationTab';
import FiltersTab from '@features/deviceSettingsFilters/components/FiltersTab';
import FlowLimitsTab from '@features/deviceSettingsFlowLimits/components/FlowLimitsTab';
import MathModelingTab from '@features/deviceSettingsMathModeling';
import RadarParametersTab from '@features/deviceSettingsRadarParameters/components/RadarParametersTab';
import RatingCurvesTab from '@features/deviceSettingsRatingCurves/components/RatingCurvesTab';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton } from '@mui/material';
import { Suspense, useEffect, useState, type FC } from 'react';
import { useDispatch } from 'react-redux';
import DeviceModalTitle from './DeviceModalTitle';

interface Props {
  onClose: () => void;
  deviceId: string;
}

interface ConfirmationDialog {
  title: string;
  content: string;
  action: () => void;
  actionButtonText: string;
}

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { _ } = useLingui();
  const { data: access } = useSuspenseQuery(GET_ACCESS_DEVICE_DATA_CONFIGURATION, {
    variables: {
      input: {
        deviceId,
      },
    },
  });
  const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationDialog | null>(null);
  const [dirtyForm, setDirtyForm] = useState(false);

  // Prevent user from leaving the page if the form is dirty
  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      if (dirtyForm) event.preventDefault();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dirtyForm]);

  const handleClose = () => {
    if (dirtyForm) {
      setConfirmationDialog({
        title: _(msg`¿Estás seguro que deseas salir?`),
        content: _(msg`Los cambios que hayas realizado no se guardarán.`),
        action: () => {
          onClose();
          setDirtyForm(false);
          setConfirmationDialog(null);
        },
        actionButtonText: _(msg`Salir sin guardar`),
      });
    } else {
      onClose();
    }
  };

  const handleChangeTab = (changeTabAction: () => void) => {
    if (dirtyForm) {
      setConfirmationDialog({
        title: _(msg`¿Estás seguro que deseas cambiar de pestaña?`),
        content: _(msg`Los cambios que hayas realizado no se guardarán.`),
        action: () => {
          changeTabAction();
          setDirtyForm(false);
          setConfirmationDialog(null);
        },
        actionButtonText: _(msg`Cambiar de pestaña`),
      });
    } else {
      changeTabAction();
    }
  };

  if (!access) return null;

  return (
    <>
      <Dialog open={confirmationDialog !== null}>
        <DialogTitle>{confirmationDialog?.title || ''}</DialogTitle>
        <DialogContent dividers>{confirmationDialog?.content || ''}</DialogContent>
        <DialogActions>
          <Button variant="outlined" color="info" onClick={() => setConfirmationDialog(null)}>
            <Trans>Volver al formulario</Trans>
          </Button>
          <Button variant="contained" color="error" onClick={confirmationDialog?.action}>
            {confirmationDialog?.actionButtonText || ''}
          </Button>
        </DialogActions>
      </Dialog>
      <DeviceModalTitle onClose={handleClose} deviceId={deviceId} />
      <ModalTabs
        changeTabMiddleware={handleChangeTab}
        panels={[
          ...(access?.authorization.authorizationDevice?.viewCalibration
            ? [
                {
                  label: _(msg`Calibración`),
                  component: (
                    <CalibrationTab
                      deviceId={deviceId}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewFlowLimit
            ? [
                {
                  label: _(msg`Límites de Caudal`),
                  component: (
                    <FlowLimitsTab
                      deviceId={deviceId}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewFilter
            ? [
                {
                  label: _(msg`Filtros`),
                  component: (
                    <FiltersTab
                      deviceId={deviceId}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewRatingCurve
            ? [
                {
                  label: _(msg`Curvas de Aforo`),
                  component: (
                    <RatingCurvesTab
                      deviceId={deviceId}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewMathModeling
            ? [
                {
                  label: _(msg`Modelamientos Matemáticos`),
                  component: (
                    <MathModelingTab
                      deviceId={deviceId}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewRadarParameter
            ? [
                {
                  label: _(msg`Parámetros Radar`),
                  component: (
                    <RadarParametersTab
                      deviceId={deviceId}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </>
  );
};

const Fallback: FC<Props> = ({ onClose, deviceId }) => (
  <>
    <DeviceModalTitle onClose={onClose} deviceId={deviceId} />
    <DialogContent dividers>
      <Skeleton variant="rounded" height={400} />
    </DialogContent>
  </>
);

const DeviceDataConfigurationModal: FC = () => {
  const dispatch = useDispatch();
  const { updateDeviceDataConfigurationModal: modalState } = useSelector(
    (state) => state.modals_store,
  );

  const onClose = () => {
    dispatch(
      setUpdateDeviceDataConfigurationModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog maxWidth="xl" fullWidth open={modalState.open}>
      {modalState.open && (
        <ApolloErrorBoundary>
          <Suspense fallback={<Fallback onClose={onClose} deviceId={modalState.deviceId} />}>
            <AuthorizationWrapper
              access={Access.DeviceDataConfigurationModal}
              deviceId={modalState.deviceId}
              onClose={onClose}
            >
              <Component onClose={onClose} deviceId={modalState.deviceId} />
            </AuthorizationWrapper>
          </Suspense>
        </ApolloErrorBoundary>
      )}
    </Dialog>
  );
};

export default DeviceDataConfigurationModal;

import { useModalStatusMessage } from '@/hooks';
import { setUpdateDeviceObservationModal } from '@/slices/modals';
import { useDispatch, useSelector } from '@/store';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { zodResolver } from '@hookform/resolvers/zod';
import { I18n } from '@lingui/core';
import { msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Dialog, DialogContent, Skeleton, TextField } from '@mui/material';
import { Suspense, type FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import UPDATE_DEVICE_OBSERVATION from '../../graphql/mutations/updateDeviceObservation';
import GET_OBSERVATION from '../../graphql/queries/getObservationData';

interface Props {
  observationId: number;
  open: boolean;
  onClose: () => void;
}

const schema = (i18n: I18n) =>
  z.object({
    content: z.string().min(2, t(i18n)`El contenido debe tener al menos 2 caracteres`),
  });

type FormValues = z.infer<ReturnType<typeof schema>>;

const Component: FC<Props> = ({ observationId, onClose }) => {
  const { _, i18n } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { data } = useSuspenseQuery(GET_OBSERVATION, {
    variables: {
      deviceObservationId: observationId,
    },
  });
  const [updateObservation] = useMutation(UPDATE_DEVICE_OBSERVATION, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Observación actualizada correctamente`));
      onClose();
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      content: data.deviceObservation.content,
    },
    resolver: zodResolver(schema(i18n)),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateObservation({
      variables: {
        input: {
          id: observationId,
          content: data.content,
        },
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <ModalTitle onClose={onClose} title={_(msg`Actualizar observación`)} />
      <DialogContent dividers>
        <Controller
          name="content"
          control={methods.control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={_(msg`Contenido`)}
              fullWidth
              rows={4}
              multiline
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </DialogContent>
      <ModalActions onClose={onClose} onSubmit="submit" />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { _ } = useLingui();
  return (
    <>
      <ModalTitle onClose={onClose} title={_(msg`Actualizar observación`)} />
      <DialogContent dividers>
        <Skeleton width="100%" variant="rounded">
          <TextField fullWidth rows={4} multiline />
        </Skeleton>
      </DialogContent>
      <ModalActions onClose={onClose} />
    </>
  );
};

const UpdateDeviceObservationModal: FC = () => {
  const dispatch = useDispatch();
  const { updateDeviceObservationModal: modalState } = useSelector((state) => state.modals_store);

  const onClose = () => {
    dispatch(
      setUpdateDeviceObservationModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={modalState.open} onClose={onClose}>
      {modalState.open && (
        <Suspense fallback={<Fallback onClose={onClose} />}>
          <Component
            observationId={modalState.observationId}
            open={modalState.open}
            onClose={onClose}
          />
        </Suspense>
      )}
    </Dialog>
  );
};

export default UpdateDeviceObservationModal;

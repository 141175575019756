import { useModalStatusMessage } from '@/hooks';
import { useSelector } from '@/store';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import StandardAutocompleteIds from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { zodResolver } from '@hookform/resolvers/zod';
import { I18n } from '@lingui/core';
import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Suspense, type FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import UPDATE_DEVICE_ALIASES from '../../graphql/mutations/updateDeviceAliases';
import GET_DEVICE_ALIASES from '../../graphql/queries/getDeviceAliases';
import { setUpdateDeviceAliasesModal } from '../../slices/deviceConfigurationSlice';

interface Props {
  deviceId: string;
  onClose: () => void;
}

const getSchema = (i18n: I18n) =>
  z.object({
    deviceId: z.string(),
    aliases: z
      .array(
        z.object({
          organizationId: z.number(),
          alias: z.string(),
        }),
      )
      .superRefine((value, ctx) => {
        if (value.length !== new Set(value.map((v) => v.organizationId)).size) {
          const repeatedIndexes = value
            .map((v, i) => ({ ...v, index: i }))
            .filter((v, i, a) => a.findIndex((x) => x.organizationId === v.organizationId) !== i)
            .map((v) => v.index);
          repeatedIndexes.forEach((index) => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(i18n)`No se pueden repetir organizaciones`,
              path: [index.toString(), 'organizationId'],
            });
            return z.NEVER;
          });
        }
      }),
  });

type FormValues = z.infer<ReturnType<typeof getSchema>>;

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { _, i18n } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { data } = useSuspenseQuery(GET_DEVICE_ALIASES, {
    variables: { deviceId },
  });
  const [update] = useMutation(UPDATE_DEVICE_ALIASES, {
    onCompleted(data) {
      openModalSuccessMessage(_(msg`Los alias del dispositivo se han actualizado correctamente`)),
        methods.reset({
          deviceId,
          aliases: data.updateDeviceAliases.profile.organizationsAliases.map((alias) => ({
            organizationId: alias.organization.id,
            alias: alias.alias,
          })),
        });
    },
    onError(error) {
      openModalErrorMessage(
        _(msg`Error al actualizar los alias del dispositivo: ${error.message}`),
      );
    },
    update(cache) {
      cache.modify({
        id: cache.identify({ __typename: 'Device', id: deviceId }),
        fields: {
          profile(_, { INVALIDATE }) {
            return INVALIDATE;
          },
        },
      });
    },
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      deviceId,
      aliases: data.device.profile.organizationsAliases.map((alias) => ({
        organizationId: alias.organization.id,
        alias: alias.alias,
      })),
    },
    resolver: zodResolver(getSchema(i18n)),
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'aliases',
  });

  const submitHandler = async (data: FormValues) => {
    await update({
      variables: {
        deviceId: data.deviceId,
        aliases: data.aliases.map((alias) => ({
          organizationId: alias.organizationId,
          alias: alias.alias,
        })),
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(submitHandler)}>
      <ModalTitle title={_(msg`Alias por organización de dispositivo`)} onClose={onClose} />
      <DialogContent dividers>
        <Typography mb={2}>
          <Trans>
            Actualizar alias de organizaciones asociadas al dispositivo{' '}
            <b>{data.device.profile.name}</b> con ID <b>{data.device.id}</b>
          </Trans>
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          {fields.map((field, index) => {
            return (
              <Box key={field.id} display="grid" gap={1} gridTemplateColumns="2fr 3fr auto">
                <Controller
                  control={methods.control}
                  name={`aliases.${index}.organizationId`}
                  render={({ field, fieldState }) => {
                    return (
                      <StandardAutocompleteIds
                        disableClearable
                        dataType="organizations"
                        value={field.value}
                        onChange={(_, values) => field.onChange(values)}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    );
                  }}
                />
                <Controller
                  control={methods.control}
                  name={`aliases.${index}.alias`}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        {...field}
                        onBlur={(event) => {
                          event.target.value = event.target.value.trim();
                          field.onChange(event);
                          field.onBlur();
                        }}
                        label={_(msg`Alias`)}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    );
                  }}
                />
                <IconButton sx={{ alignSelf: 'center' }} onClick={() => remove(index)}>
                  <Close />
                </IconButton>
              </Box>
            );
          })}
          <Button
            onClick={() =>
              append({
                organizationId: 0,
                alias: '',
              })
            }
            sx={{ alignSelf: 'start' }}
          >
            <Trans>Agregar alias</Trans>
          </Button>
        </Box>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        dirtyForm={methods.formState.isDirty}
        onSubmit="submit"
        onResetForm={() => methods.reset()}
        submitLoading={methods.formState.isSubmitting}
      />
    </form>
  );
};

const Fallback = () => {
  return (
    <DialogContent>
      <Skeleton height={300} variant="rounded" />
    </DialogContent>
  );
};

const DeviceAliasModal: FC = () => {
  const dispatch = useDispatch();
  const { updateDeviceAliasesModal: modalState } = useSelector(
    (state) => state.device_configuration_store,
  );

  const onClose = () => {
    dispatch(setUpdateDeviceAliasesModal({ open: false }));
  };

  return (
    <Dialog maxWidth="md" fullWidth open={modalState.open} onClose={onClose}>
      <ApolloErrorBoundary>
        <Suspense fallback={<Fallback />}>
          {modalState.open && <Component deviceId={modalState.deviceId} onClose={onClose} />}
        </Suspense>
      </ApolloErrorBoundary>
    </Dialog>
  );
};

export default DeviceAliasModal;

import { useSuspenseQuery } from '@apollo/client';
import FORMAT_DEVICE_TYPE_GET_DEVICE from '@/graphql/querys/formatDeviceTypeGetDevice';
import { newFormatSourceColor } from '@/utils/devices/formatSourceColor';

export const useFormatDeviceType = (deviceId: string) => {
  const { data } = useSuspenseQuery(FORMAT_DEVICE_TYPE_GET_DEVICE, {
    variables: {
      deviceId,
    },
  });

  const formattedType = newFormatSourceColor({
    profile: {
      type: data.device.profile.type,
      source: data.device.profile.source,
      sourceInfo: data.device.profile.sourceInfo,
      sensor: data.device.profile.sensor,
    },
  });

  return {
    color: formattedType.color,
    text: formattedType.text,
    extraInfo: formattedType.extraInfo,
  };
};

// react
import { useEffect, useState } from 'react';

// material-ui
import {
  Badge,
  Box,
  IconButton,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

// graphql
import { useApolloClient, useSubscription } from '@apollo/client';
import FETCH_LAST_COMMENTS from 'src/graphql/querys/fetchLastComments';
import COMMENTS from 'src/graphql/subscriptions/comments';

// utils
import _ from 'lodash';
import moment from 'moment';

// hooks
import useAuth from 'src/hooks/useAuth';

// react-router
import { useNavigate } from 'react-router';
import { Trans } from '@lingui/macro';

const NotificationsPopover = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [commentsState, setCommentsState] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [badgeContent, setBadgeContent] = useState(0);

  const [type, setType] = useState('comments');

  const [alerts, setAlerts] = useState([]);
  const [alertsLoading, setAlertsLoading] = useState(false);

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('md'));

  const { data: subscriptionData } = useSubscription(COMMENTS, {
    variables: { accountId: auth.user.id },
  });

  const getComments = async () => {
    setCommentsLoading(true);
    const {
      data: { FetchLastComments },
    } = await client.query({
      query: FETCH_LAST_COMMENTS,
      fetchPolicy: 'no-cache',
    });
    if (FetchLastComments) {
      const data = FetchLastComments.map((comment) => ({
        ..._.omit(comment, '__typename'),
        account: _.omit(comment.account, '__typename'),
        component: _.omit(comment.component, '__typename'),
      }));
      setCommentsState(data);
    }
    setCommentsLoading(false);
  };

  useEffect(() => {
    if (subscriptionData) {
      const { comments } = subscriptionData;
      const newComments = [comments, ...commentsState];
      setCommentsState(newComments);
      setBadgeContent(badgeContent + 1);
    }
  }, [subscriptionData]);

  useEffect(() => {
    getComments();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setBadgeContent(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setType(newAlignment);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Tooltip title="Notificaciones">
        <IconButton color="inherit" onClick={handleClick} size="large">
          <Badge badgeContent={badgeContent} color="primary">
            <NotificationsIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          mt: 2,
        }}
      >
        <Box p={2} maxHeight={isXS ? 300 : 500} width={isXS ? 310 : 400}>
          <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            fullWidth
            onChange={handleAlignmentChange}
            sx={{
              mb: 2,
            }}
          >
            <ToggleButton value="alerts">
              <Trans>Alertas</Trans>
            </ToggleButton>
            <ToggleButton value="comments">
              <Trans>Comentarios</Trans>
            </ToggleButton>
          </ToggleButtonGroup>
          {type === 'comments' ? (
            <Box>
              {commentsLoading && (
                <Typography>
                  <Trans>Cargando...</Trans>
                </Typography>
              )}
              {commentsState.length > 0 ? (
                commentsState.map((comment) => (
                  <Box mb={4} key={`comment-${comment.created}`}>
                    <Typography
                      fontWeight="bold"
                      color="textSecondary"
                      sx={{
                        mb: 1,
                      }}
                    >
                      {moment(new Date(parseInt(comment.created, 10))).format('DD/MM/YYYY HH:mm a')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mb={2}>
                      <Typography
                        sx={{
                          mr: 0.7,
                          fontSize: '0.9rem',
                        }}
                      >
                        <Trans>El usuario</Trans>
                      </Typography>
                      <Typography
                        sx={{
                          mr: 0.7,
                          fontSize: '0.9rem',
                          // '&:hover': {
                          //   cursor: 'pointer',
                          // },
                          color: '#78bcff',
                        }}
                      >
                        {_.get(comment, 'account.name', '')}{' '}
                        {_.get(comment, 'account.lastnames', '')}
                      </Typography>
                      <Typography
                        sx={{
                          mr: 0.7,
                          fontSize: '0.9rem',
                        }}
                      >
                        <Trans>ha comentado en</Trans>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '0.9rem',
                          // '&:hover': {
                          //   cursor: 'pointer',
                          // },
                          color: '#78bcff',
                        }}
                      >
                        {comment.component.name}.
                      </Typography>
                    </Box>
                    <Typography key={comment.id}>{comment.contain}</Typography>
                  </Box>
                ))
              ) : (
                <Typography sx={{ p: 2 }}>
                  <Trans>No hay notificaciones.</Trans>
                </Typography>
              )}
            </Box>
          ) : (
            <Box>
              {alertsLoading && (
                <Typography>
                  <Trans>Cargando...</Trans>
                </Typography>
              )}
              {alerts.length > 0 ? (
                alerts.map((alert) => (
                  <Box mb={4} key={`alert-${alert.created}`}>
                    <Typography
                      fontWeight="bold"
                      color="textSecondary"
                      sx={{
                        mb: 1,
                      }}
                    >
                      {moment(new Date(parseInt(alert.created, 10))).format('DD/MM/YYYY HH:mm a')}
                    </Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" mb={2}>
                      <Typography
                        sx={{
                          mr: 0.7,
                          fontSize: '0.9rem',
                        }}
                      >
                        <Trans>Se ha detectado un evento en</Trans>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '0.9rem',
                          // '&:hover': {
                          //   cursor: 'pointer',
                          // },
                          color: '#78bcff',
                        }}
                      >
                        {alert.component.name}.
                      </Typography>
                    </Box>
                    <Typography>{alert.detail}</Typography>
                    {alert.showDistributionLink && (
                      <Typography
                        sx={{
                          fontSize: '0.9rem',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                          color: '#78bcff',
                        }}
                        onClick={() =>
                          navigate(
                            {
                              pathname: `/distribution-history/section/${alert.irrigation_channel_network_id}`,
                            },
                            { replace: true },
                          )
                        }
                      >
                        <Trans>Ver detalles</Trans>
                      </Typography>
                    )}
                  </Box>
                ))
              ) : (
                <Typography sx={{ p: 2 }}>
                  <Trans>No hay alertas.</Trans>
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default NotificationsPopover;

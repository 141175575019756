import { gql } from '@/__generated__';

const UPDATE_DEVICE_GENERAL = gql(`#graphql
  mutation DeviceConfiguration_UpdateDeviceGeneral($deviceId: ID!, $input: UpdateDeviceInput!) {
    updateDevice(deviceId: $deviceId, input: $input) {
      id
      profile {
        name
        alias
        originalName
        status: status2
        type: type2
        irrigation {
          id
          name
        }
        organization {
          id
          name
        }
        location {
          latitude
          longitude
        }
      }
    }
  }
`);

export default UPDATE_DEVICE_GENERAL;

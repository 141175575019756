import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { ComponentType, useEffect, useRef, useState } from 'react';
import { DialogEnum } from './DialogEnum';
import { DeviceDialogComponents } from './dialogs';
import isRegis from 'src/utils/devices/isRegis';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';
import useAccess from 'src/hooks/useAccess';
import { useLazyQuery } from '@apollo/client';
import GET_DEVICE_OPTIONS_DATA from './getDeviceOptionsData';
import GET_ACCESS_DEVICE_OPTIONS from 'src/graphql/querys/access/getAccessDeviceOptions';
import Loading from '../Loading';
import { useDeviceConfigurationModal } from 'src/features/deviceConfigurationModal/hooks/useDeviceConfigurationModal';
import { useDispatch } from 'react-redux';
import { setUpdateDeviceConfigurationModal } from 'src/features/deviceConfigurationModal/slices/deviceConfigurationSlice';
import {
  setUpdateDeviceAlertsModal,
  setUpdateDeviceDataConfigurationModal,
} from 'src/slices/modals';

interface Props {
  reloadData: () => void;
  CustomIcon?: ComponentType;
  deviceId: string;
}

const DeviceOptions = ({ reloadData, CustomIcon, deviceId }: Props) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const [fetch, { data, called }] = useLazyQuery(GET_DEVICE_OPTIONS_DATA, {
    variables: {
      input: {
        id: deviceId,
      },
    },
  });

  const { hasAccess } = useAccess();

  const [fetchAccess, { data: dataAccess, called: calledAccess }] = useLazyQuery(
    GET_ACCESS_DEVICE_OPTIONS,
    {
      variables: {
        input: {
          deviceId,
        },
      },
    },
  );

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const [shouldRenderDialogs, setShouldRenderDialogs] = useState<boolean>(false);
  const [dialogToShow, setDialogToShow] = useState<DialogEnum>(DialogEnum.null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  useEffect(() => {
    if (!called && openMenu) {
      fetch();
    }
  }, [called, openMenu]);

  useEffect(() => {
    if (!calledAccess) {
      fetchAccess();
    }
  }, [calledAccess, openMenu]);

  const deviceOLD = data
    ? ({
        id: data.device.id,
        name: data.device.profile.name,
        type: data.device.profile.type,
        source: data.device.profile.source,
        sensor: data.device.profile.sensor,
        source_info: data.device.profile.sourceInfo,
        parent_component_id: data.device.profile.componentID,
        status: data.device.profile.status,
        logical_device_id: data.device.profile.logicalDeviceID,
        logicalDeviceId: data.device.profile.logicalDeviceID,
        organization_id: data.device.profile.organization?.id,
        organization_name: data.device.profile.organization?.name,
        icn_id: data.device.profile.irrigation?.id,
        icn_name: data.device.profile.irrigation?.name,
        distribution_max_flow: data.device.alerts?.distributionLimits?.maxFlow,
        // last_photo: device.regis?.lastPhoto,
        alerts: {
          flow_min: data.device.alerts?.lastFlow?.min,
          flow_max: data.device.alerts?.lastFlow?.max,
          flow_overflow: data.device.alerts?.lastFlow?.overflow,
          level_min: data.device.alerts?.lastLevel?.min,
          level_max: data.device.alerts?.lastLevel?.max,
          overflow_level: data.device.alerts?.lastLevel?.overflow,
        },
      } as any)
    : null;

  const { source, source_info } = deviceOLD ?? {};

  useEffect(() => {
    if (openMenu) {
      setShouldRenderDialogs(true);
    }
  }, [openMenu]);

  const handleMenuOpen = (): void => {
    setOpenMenu(true);
  };

  const handleMenuClose = (): void => {
    setOpenMenu(false);
  };

  const handleShowEdit = () => {
    setDialogToShow(DialogEnum.edit);
    handleMenuClose();
  };

  const handleShowDelete = () => {
    setDialogToShow(DialogEnum.delete);
    handleMenuClose();
  };

  const handleShowCreateLevelAlerts = () => {
    dispatch(
      setUpdateDeviceAlertsModal({
        open: true,
        deviceId,
      }),
    );
    handleMenuClose();
  };

  const handleShowDeviceProfile = () => {
    setDialogToShow(DialogEnum.deviceProfile);
    handleMenuClose();
  };

  const handleShowCreateMeasurement = () => {
    setDialogToShow(DialogEnum.createMeasurement);
    handleMenuClose();
  };

  const handleShowNotifications = () => {
    setDialogToShow(DialogEnum.deviceNotifications);
    handleMenuClose();
  };

  const handleShowChangeStatus = () => {
    setDialogToShow(DialogEnum.changeStatus);
    handleMenuClose();
  };

  const handleShowAliases = () => {
    setDialogToShow(DialogEnum.deviceNameAliases);
    handleMenuClose();
  };

  const handleShowManualData = () => {
    setDialogToShow(DialogEnum.manualData);
    handleMenuClose();
  };

  const handleShowUserList = () => {
    setDialogToShow(DialogEnum.userList);
    handleMenuClose();
  };

  const handleShowStatusHistory = () => {
    setDialogToShow(DialogEnum.statusHistory);
    handleMenuClose();
  };

  const handleShowClone = () => {
    setDialogToShow(DialogEnum.clone);
    handleMenuClose();
  };

  const handleDialogClose = (shouldRefetch: boolean) => {
    if (shouldRefetch) {
      reloadData();
    }
    setDialogToShow(DialogEnum.null);
    setShouldRenderDialogs(false);
  };

  return (
    <>
      {CustomIcon ? (
        <Tooltip title={_(msg`Opciones de dispositivo`)}>
          <IconButton onClick={handleMenuOpen} ref={anchorRef}>
            <CustomIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton onClick={handleMenuOpen} ref={anchorRef} size="large">
          <SettingsIcon fontSize="large" />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 280,
          },
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        {!data ? (
          <Loading />
        ) : (
          <>
            {dataAccess?.authorization.authorizationDevice?.updateAlias && (
              <MenuItem onClick={() => handleShowAliases()}>
                <ListItemText primary={_(msg`Alias en dispositivos`)} />
              </MenuItem>
            )}
            {dataAccess?.authorization.authorizationDevice?.updateState && (
              <MenuItem onClick={handleShowChangeStatus}>
                <ListItemText primary={_(msg`Cambio de estado`)} />
              </MenuItem>
            )}
            {dataAccess?.authorization.authorizationDevice?.clone &&
              (source === 'physical' ||
                (source === 'virtual' &&
                  ['downstream', 'upstream', 'slave frankie'].includes(source_info))) && (
                <MenuItem onClick={handleShowClone}>
                  <ListItemText primary={_(msg`Clonar dispositivo`)} />
                </MenuItem>
              )}
            {dataAccess?.authorization.authorizationDevice?.viewDeviceConfiguration && (
              <MenuItem
                onClick={() => {
                  dispatch(
                    setUpdateDeviceDataConfigurationModal({
                      open: true,
                      deviceId,
                    }),
                  );
                  handleMenuClose();
                }}
              >
                <ListItemText primary={_(msg`Configuración de datos`)} />
              </MenuItem>
            )}
            {dataAccess?.authorization.authorizationDevice?.update && (
              <MenuItem onClick={handleShowEdit}>
                <ListItemText primary={_(msg`Editar`)} />
              </MenuItem>
            )}
            {hasAccess('Sec:ConfiguracionAlertas') && (
              <MenuItem onClick={handleShowCreateLevelAlerts}>
                <ListItemText primary={_(msg`Editar alertas`)} />
              </MenuItem>
            )}
            {dataAccess?.authorization.authorizationDevice?.delete && (
              <MenuItem onClick={handleShowDelete}>
                <ListItemText primary={_(msg`Eliminar`)} />
              </MenuItem>
            )}
            <MenuItem onClick={handleShowNotifications}>
              <ListItemText primary={_(msg`Historial de alertas y mensajes`)} />
            </MenuItem>
            {dataAccess?.authorization.authorizationDevice?.viewStateHistorial && (
              <MenuItem onClick={handleShowStatusHistory}>
                <ListItemText primary={_(msg`Historial de estados`)} />
              </MenuItem>
            )}
            {isRegis(deviceOLD) && (
              <MenuItem key="edit" onClick={handleShowCreateMeasurement}>
                <ListItemText primary={_(msg`Ingreso manual de datos`)} />
              </MenuItem>
            )}
            {dataAccess?.authorization.authorizationDevice?.viewProfile && (
              <MenuItem onClick={handleShowDeviceProfile}>
                <ListItemText primary={_(msg`Perfil del equipo`)} />
              </MenuItem>
            )}
            {dataAccess?.authorization.authorizationDevice?.viewUserAccess && (
              <MenuItem onClick={handleShowUserList}>
                <ListItemText primary={_(msg`Usuario con acceso y alertas`)} />
              </MenuItem>
            )}
            {isRegis(deviceOLD) && (
              <MenuItem onClick={handleShowManualData}>
                <ListItemText primary={_(msg`Ver tabla de datos`)} />
              </MenuItem>
            )}
          </>
        )}
      </Menu>
      {shouldRenderDialogs && data && (
        <DeviceDialogComponents
          dialogToShow={dialogToShow}
          handleDialogClose={handleDialogClose}
          device={deviceOLD}
        />
      )}
    </>
  );
};

export default DeviceOptions;

import { gql } from '@/__generated__';

const IS_REGIS_DATA = gql(`#graphql
  query IsRegisData($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        source
        sourceInfo
      }
    }
  }
`);

export default IS_REGIS_DATA;

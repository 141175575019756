import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import FETCH_LAST_DISTRIBUTION from 'src/graphql/querys/sira/lastDistributionInstance';
import DISTRIBUTION_CLASS from 'src/graphql/querys/sira/distributionClass';
import { useDispatch, useSelector } from 'src/store';
import { setDistributionStationIds, setShowDistributionOptions } from 'src/slices/map';
import { useLocation } from 'react-router';
import { Trans } from '@lingui/macro';
import TimeElapsed from './TimeElapsed';
import { useFormatters } from '@/hooks/useFormatters';

const LastDistributionData = () => {
  const { formatNumber } = useFormatters();
  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedIrrigationIds } = useSelector((state) => state.control_center_store);
  const [selectedIrrigationId] = selectedIrrigationIds;
  const { stations } = useSelector((state) => state.map_store);
  const [canalClassToSum, setCanalClassToSum] = useState(null);
  const [actualDistributionFlow, setActualDistributionFlow] = useState(null);
  const { data: distributionData, loading: distributionLoading } = useQuery(
    FETCH_LAST_DISTRIBUTION,
    {
      skip: selectedIrrigationId == null,
      variables: {
        irrigation_channel_network_id: selectedIrrigationId,
      },
    },
  );
  useQuery(DISTRIBUTION_CLASS, {
    skip: selectedIrrigationId == null,
    variables: {
      irrigation_channel_network_id: selectedIrrigationId,
    },
    onCompleted: (data) => {
      if (data) {
        const { canals } = data.Sira.distributionClass;
        setCanalClassToSum(canals.filter((canal) => canal.distribution_sum));
      }
    },
  });
  const distribution = distributionData?.Sira?.FetchLastDistributionInstance;

  useEffect(() => {
    if (stations && distribution) {
      dispatch(setShowDistributionOptions(true));
      dispatch(
        setDistributionStationIds(distribution.canals.map((canal) => canal.inlet_device_id)),
      );
    } else {
      dispatch(setShowDistributionOptions(false));
    }
  }, [stations, distribution]);

  useEffect(() => {
    if (canalClassToSum && stations) {
      const distributionStationIds = canalClassToSum.map((canal) => canal.inlet_device_id);
      setActualDistributionFlow(
        stations.reduce((acc, station) => {
          const { id, flow } = station;
          if (distributionStationIds.includes(id)) {
            return acc + flow;
          }
          return acc;
        }, 0),
      );
    }
  }, [canalClassToSum, stations]);

  if (location.pathname !== '/' || !distribution) return null;

  return (
    <Box
      display="flex"
      flexDirection={['column', 'row']}
      flexWrap="wrap"
      justifyContent="space-around"
    >
      <Box display="flex" flexDirection="row" mr={2} mb={1}>
        <Typography
          sx={{
            mr: 1,
            fontSize: '0.8rem',
          }}
        >
          <Trans>Total Reparto</Trans>
        </Typography>
        {distributionLoading ? (
          <Typography fontSize="0.8rem">
            <Trans>Cargando...</Trans>
          </Typography>
        ) : (
          <Typography fontWeight="bold" fontSize="0.8rem">
            {distribution && distribution.distribution_waterflow !== null
              ? formatNumber(distribution.distribution_waterflow) + ' L/s'
              : '-'}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="row" mr={2} mb={1}>
        <Typography
          sx={{
            mr: 1,
            fontSize: '0.8rem',
          }}
        >
          <Trans>Flujo Total Actual</Trans>
        </Typography>
        {distributionLoading ? (
          <Typography fontSize="0.8rem">
            <Trans>Cargando...</Trans>
          </Typography>
        ) : (
          <Typography fontWeight="bold" fontSize="0.8rem">
            {actualDistributionFlow !== null ? formatNumber(actualDistributionFlow) + ' L/s' : '-'}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="row">
        {/*{distribution && distribution.expected_lossvalue && (
          <Typography
            sx={{
              mr: 1,
              fontSize: "0.8rem",
            }}
          >
            Pérdida
          </Typography>
        )}*/}
        {distributionLoading ? (
          <Typography fontSize="0.8rem">
            <Trans>Cargando...</Trans>
          </Typography>
        ) : (
          distribution &&
          distribution.expected_lossvalue && (
            <Box display="flex" flexDirection="row">
              <Typography
                fontWeight="bold"
                sx={{
                  mr: 0.5,
                  fontSize: '0.8rem',
                }}
              >
                {distribution.expected_lossvalue}
              </Typography>
              <Typography fontWeight="bold" fontSize="0.8rem">
                {distribution.expected_lossunit}
              </Typography>
            </Box>
          )
        )}
      </Box>
      <Box display="flex" flexDirection="row">
        <Typography
          sx={{
            mr: 1,
            fontSize: '0.8rem',
          }}
        >
          <Trans>Último reparto</Trans>
        </Typography>
        {distributionLoading ? (
          <Typography fontSize="0.8rem">
            <Trans>Cargando...</Trans>
          </Typography>
        ) : (
          <Typography fontWeight="bold" fontSize="0.8rem">
            {distribution && distribution.distribution_start_date ? (
              <TimeElapsed
                timestamp={new Date(parseInt(distribution.distribution_start_date)).getTime()}
              />
            ) : (
              '-'
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LastDistributionData;

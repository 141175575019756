import { DgaReports, DgaType, MetricUnit } from '@/__generated__/graphql';
import { useFormatters } from '@/hooks/useFormatters';
import { useSelector } from '@/store';
import { useSuspenseQuery } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import GET_DGA_REPORTS from '@features/dga/graphql/queries/getDGAReports';
import { getNextExecutionDateMessage, getStatusRankForChart } from '@features/dga/utils';
import Echarts, { EChartsOptionBuilder } from '@features/echarts';
import { Trans } from '@lingui/macro';
import { Box, Dialog, DialogContent, Skeleton } from '@mui/material';
import { amber, green } from '@mui/material/colors';
import moment from 'moment';
import { Suspense, useMemo, type FC } from 'react';
import { useDispatch } from 'react-redux';
import { setDGAReportsChartModal } from '../../slices/dgaSlice';

interface Props {
  deviceId: string;
  onClose: () => void;
}

const statusToCategory = {
  0: 'Publicado',
  1: 'En reintento',
};

const date = {
  start: moment().local().subtract(7, 'days').startOf('day').valueOf() / 1000,
  stop: moment().local().valueOf() / 1000,
};

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { formatMetricValue } = useFormatters();
  const { data } = useSuspenseQuery(GET_DGA_REPORTS, {
    variables: {
      deviceIdString: deviceId,
      deviceId: deviceId,
      start: date.start,
      stop: date.stop,
    },
  });

  const option = useMemo(() => {
    if (!data) return {};
    const reports = data?.GetDGAReports ?? [];

    const dateCategories = Array.from({ length: 8 }, (_, i) => i)
      .map((i) => moment().subtract(i, 'days').format('DD/MM/yyyy'))
      .reverse();
    const hourCategories = Array.from({ length: 24 }, (_, i) => i).map((i) => {
      return moment().hour(i).minutes(0).format('HH:mm');
    });
    const chartData: [number, number, number, DgaReports][] = [];
    reports.forEach((d) => {
      if (!d) return;
      const { timestamp } = d;
      const dateIndex = dateCategories.indexOf(moment.utc(timestamp).local().format('DD/MM/yyyy'));
      const hourIndex = hourCategories.indexOf(moment.utc(timestamp).local().format('HH:mm'));
      if (dateIndex !== -1 && hourIndex !== -1) {
        const status = getStatusRankForChart(d.success ?? false);
        chartData.push([dateIndex, hourIndex, status, d]);
      }
    });

    const option = new EChartsOptionBuilder({
      toolbox: {
        disabled: true,
      },
      override(option) {
        return {
          ...option,
          grid: {
            top: 40,
            left: 0,
            right: 0,
            bottom: 10,
            containLabel: true,
          },
          legend: {
            show: false,
          },
          visualMap: {
            top: 0,
            orient: 'horizontal',
            textStyle: {
              color: 'white',
            },
            type: 'piecewise',
            categories: Object.values(statusToCategory),
            dimension: 2,
            pieces: [
              {
                value: 0,
                label: statusToCategory[0],
                color: green[700],
              },
              {
                value: 1,
                label: statusToCategory[1],
                color: amber[700],
              },
            ],
          },
          tooltip: {
            ...option.tooltip,
            trigger: 'item',
            position: 'top',
            formatter(params: any) {
              const { value } = params;
              const report: DgaReports = value[3];
              const {
                values,
                error_message: errorMessage,
                execution_timestamp: executionTimestamp,
                success,
              } = report;
              const nextExecutionTimestamp = getNextExecutionDateMessage(executionTimestamp);
              const day = dateCategories[value[0]];
              const hour = hourCategories[value[1]];
              const { flow, level, accumulated_volume } = values ?? {};
              const category = statusToCategory[value[2] as 0 | 1];
              return `${params.marker} ${day}, ${hour}: <b>${category}</b><br />
${
  executionTimestamp
    ? `Fecha de ejecución: ${moment
        .utc(executionTimestamp)
        .local()
        .format('DD/MM/yyyy HH:mm')}<br />`
    : ''
}
${!success ? `${nextExecutionTimestamp}` : ''}
${errorMessage ? `<b style="text-wrap: wrap">${errorMessage}</b><br />` : ''}
${
  flow != null
    ? `Caudal: <b>${formatMetricValue(flow, {
        unit: MetricUnit.LiterPerSecond,
        precision: 0,
      })}</b><br />`
    : ''
}
${
  level != null
    ? `Nivel: <b>${formatMetricValue(level, {
        unit:
          data.device.dgaConfiguration?.type === DgaType.Underground
            ? MetricUnit.Meter
            : MetricUnit.Centimeter,
        precision: 0,
      })}</b><br />`
    : ''
}
${
  accumulated_volume != null
    ? `Volumen acumulado: <b>${formatMetricValue(accumulated_volume, {
        unit: MetricUnit.CubicMeter,
        precision: 0,
      })}</b><br />`
    : ''
}
`;
            },
          },
        };
      },
    });
    option
      .addXAxis(
        {
          id: 'day',
          type: 'category',
        },
        (xAxis) => ({
          ...xAxis,
          data: dateCategories,
          splitArea: {
            show: true,
          },
        }),
      )
      .addYAxis(
        {
          id: 'hour',
          type: 'category',
        },
        (yAxis) => ({
          ...yAxis,
          data: hourCategories,
          splitArea: {
            show: true,
          },
          axisLabel: {
            ...yAxis.axisLabel,
            showMinLabel: true,
          },
        }),
      );

    option.addSeries([
      {
        meta: {
          type: 'heatmap',
          data: chartData,
          name: 'Reportes',
          yAxisId: 'hour',
          xAxisId: 'day',
        },
        value(value) {
          return {
            ...value,
            itemStyle: {
              borderWidth: 1,
              borderColor: 'rgba(0, 0, 0, 0.2)',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 4,
                shadowColor: 'rgba(0, 0, 0, 0.3)',
              },
            },
          };
        },
      },
    ]);

    return option;
  }, [data]);

  return (
    <>
      <ModalTitle
        onClose={onClose}
        title={<Trans>Gráfico de reportes DGA {data.device.profile.name}</Trans>}
      />
      <DialogContent dividers>
        <Box height={500}>
          <Echarts option={option} />
        </Box>
      </DialogContent>
    </>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <ModalTitle
      onClose={onClose}
      title={
        <Box display="flex" gap={1}>
          <Trans>Gráfico de reportes DGA</Trans> <Skeleton variant="text" width={200} />
        </Box>
      }
    />
    <DialogContent dividers>
      <Skeleton variant="rounded" height={500} />
    </DialogContent>
  </>
);

const DGAReportsChartModal: FC = () => {
  const dispatch = useDispatch();
  const { reportsChartModal } = useSelector((state) => state.dga_store);

  const onClose = () => {
    dispatch(
      setDGAReportsChartModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={reportsChartModal.open} maxWidth="md" fullWidth>
      <Suspense fallback={<Fallback onClose={onClose} />}>
        {reportsChartModal.open && (
          <Component onClose={onClose} deviceId={reportsChartModal.deviceId} />
        )}
      </Suspense>
      <ModalActions onClose={onClose} />
    </Dialog>
  );
};

export default DGAReportsChartModal;

import { Button, type ButtonProps } from '@mui/material';
import { forwardRef, type FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = ButtonProps<'a'> & { href: string };

const ButtonLink: FC<Props> = (props) => (
  <Button
    {...props}
    LinkComponent={forwardRef((props, ref) => (
      <RouterLink ref={ref} {...props} to={props.href ?? ''} />
    ))}
  >
    {props.children}
  </Button>
);

export default ButtonLink;

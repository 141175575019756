import { DeviceType } from '@/__generated__/graphql';
import { getDeviceTypeOptions } from './getDeviceTypeOptions';

const familyTypes = [
  {
    family: 1,
    types: [DeviceType.T_5L, DeviceType.TCoreL, DeviceType.TNanoL, DeviceType.TMicroL],
  },
  {
    family: 2,
    types: [DeviceType.T_5Lv, DeviceType.TCoreLv, DeviceType.TMicroLv],
  },
  {
    family: 3,
    types: [DeviceType.T_5Lg, DeviceType.TCoreLg, DeviceType.TMicroLg],
  },
];

/**
 * Given a device type, returns the options of the device types that belong to the same family.
 * If the device type does not belong to a family, returns the device type option of the given type.
 */
export const getDeviceTypeOptionsFromSameFamily = (type: DeviceType) => {
  const deviceTypeOptions = getDeviceTypeOptions();
  const family = familyTypes.find((familyType) => familyType.types.includes(type));
  if (!family) {
    const deviceTypeOption = deviceTypeOptions.find(
      (deviceTypeOption) => deviceTypeOption.enumValue === type,
    )!;
    return [deviceTypeOption];
  } else {
    const familyOptions = family.types.flatMap((type) => {
      const deviceTypeOption = deviceTypeOptions.find(
        (deviceTypeOption) => deviceTypeOption.enumValue === type,
      );
      return deviceTypeOption ?? [];
    });
    return familyOptions;
  }
};

import { useSuspenseQuery } from '@apollo/client';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  DeveloperBoard,
  DeveloperBoardOff,
  NotificationsActive,
  NotificationsOff,
  Send,
  Wifi,
  WifiOff,
} from '@mui/icons-material';
import { Avatar, AvatarGroup, Box, Chip, Skeleton, Tooltip, Typography } from '@mui/material';
import { Suspense, type FC } from 'react';
import { useDispatch } from 'react-redux';
import ApolloErrorBoundary from 'src/components/ApolloErrorBoundary';
import { setUpdateDGAConfigurationModal } from 'src/features/dga/slices/dgaSlice';
import { setUpdateDeviceAlertsModal } from 'src/slices/modals';
import GET_DEVICE_PROFILE_STATUS from '../../graphql/queries/getDeviceStatus';

interface Props {
  deviceId: string;
}

const Component: FC<Props> = ({ deviceId }) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();

  const { data } = useSuspenseQuery(GET_DEVICE_PROFILE_STATUS, {
    variables: {
      input: {
        id: deviceId,
      },
    },
  });

  const yesterdayTimestamp = new Date().getTime() - 24 * 60 * 60 * 1000;

  const connected = [
    data.device.metrics.flow.lastData?.[0] ?? 0,
    data.device.metrics.level.lastData?.[0] ?? 0,
    data.device.metrics.velocity.lastData?.[0] ?? 0,
  ].some((timestamp) => timestamp > yesterdayTimestamp);

  const hasAlertConfigurations = Boolean(
    data.device.alerts.lastFlow || data.device.alerts.lastLevel,
  );
  const hasDgaConfiguration = Boolean(data.device.dgaConfiguration);
  const hasHardwareConfiguration = Boolean(data.device.hardwareConfiguration);

  const openAlertsConfigurationModal = () => {
    dispatch(
      setUpdateDeviceAlertsModal({
        open: true,
        deviceId,
      }),
    );
  };

  const openDgaConfigurationModal = () => {
    dispatch(
      setUpdateDGAConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  const statusItems = [
    {
      icon: Wifi,
      color: 'success.main',
      title: _(msg`Conectado recientemente`),
      show: connected,
      description: (
        <Typography fontSize="inherit">
          <Trans>El dispositivo tiene datos las últimas 24 horas</Trans>
        </Typography>
      ),
    },
    {
      icon: WifiOff,
      color: 'disabled.main',
      title: _(msg`Desconectado`),
      show: !connected,
      description: (
        <Typography fontSize="inherit">
          <Trans>El dispositivo no tiene datos las últimas 24 horas</Trans>
        </Typography>
      ),
    },
    {
      icon: NotificationsActive,
      color: 'primary.main',
      title: _(msg`Alertas configuradas`),
      show: hasAlertConfigurations,
      description: (
        <Box>
          <Typography fontSize="inherit">
            <Trans>Alertas configuradas para este dispositivo</Trans>
          </Typography>
          <Chip
            label={_(msg`Ver configuración de alertas`)}
            color="info"
            onClick={openAlertsConfigurationModal}
            size="small"
            sx={{ mt: 0.5 }}
          />
        </Box>
      ),
    },
    {
      icon: NotificationsOff,
      color: 'warning.main',
      title: _(msg`Alertas no configuradas`),
      show: !hasAlertConfigurations,
      description: (
        <Box>
          <Typography fontSize="inherit">
            <Trans>No hay alertas configuradas para este dispositivo</Trans>
          </Typography>
          <Chip
            label={_(msg`Crear configuración de alertas`)}
            color="info"
            onClick={openAlertsConfigurationModal}
            size="small"
            sx={{ mt: 0.5 }}
          />
        </Box>
      ),
    },
    {
      icon: Send,
      color: 'primary.main',
      title: _(msg`Configuración DGA`),
      show: hasDgaConfiguration,
      description: (
        <Box>
          <Typography fontSize="inherit">
            <Trans>El dispositivo tiene configuración DGA</Trans>
          </Typography>
          <Chip
            label={_(msg`Ver configuración DGA`)}
            color="info"
            onClick={openDgaConfigurationModal}
            size="small"
            sx={{ mt: 0.5 }}
          />
        </Box>
      ),
    },
    {
      icon: DeveloperBoard,
      color: 'primary.main',
      title: _(msg`Configuración de hardware`),
      show: hasHardwareConfiguration,
      description: (
        <Typography fontSize="inherit">
          <Trans>El dispositivo tiene configuración de hardware</Trans>
        </Typography>
      ),
    },
    {
      icon: DeveloperBoardOff,
      color: 'warning.main',
      title: _(msg`Sin configuración de hardware`),
      show: !hasHardwareConfiguration,
      description: (
        <Typography fontSize="inherit">
          <Trans>El dispositivo no tiene configuración de hardware</Trans>
        </Typography>
      ),
    },
  ];

  return (
    <Box display="flex" alignSelf={{ sx: 'start', sm: 'center' }}>
      <AvatarGroup>
        {statusItems.map(
          (item) =>
            item.show && (
              <Tooltip
                title={
                  <Box>
                    <Typography fontWeight="bold" fontSize="inherit" lineHeight={1.2}>
                      {item.title}
                    </Typography>
                    {item.description}
                  </Box>
                }
              >
                <Avatar sx={{ bgcolor: item.color, width: 32, height: 32 }}>
                  <item.icon fontSize="small" />
                </Avatar>
              </Tooltip>
            ),
        )}
      </AvatarGroup>
    </Box>
  );
};

const Fallback: FC = () => {
  return (
    <Box display="flex" alignSelf={{ sx: 'start', sm: 'center' }}>
      <AvatarGroup>
        {Array.from({ length: 3 }).map(() => (
          <Skeleton variant="circular" width={32} height={32} />
        ))}
      </AvatarGroup>
    </Box>
  );
};

const DeviceProfileStatus: FC<Props> = (props) => {
  return (
    <ApolloErrorBoundary>
      <Suspense fallback={<Fallback />}>
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default DeviceProfileStatus;

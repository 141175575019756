import { gql } from '@/__generated__';

const DELETE_DEVICE_OBSERVATION = gql(`#graphql
  mutation DeviceObservation_DeleteObservation($input: DeleteDeviceObservationInput!) {
    deleteDeviceObservation(input: $input) {
      id
    }
  }
`);

export default DELETE_DEVICE_OBSERVATION;

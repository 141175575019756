import { useSuspenseQuery } from '@apollo/client';
import IS_REGIS_DATA from '@/graphql/querys/isRegisData';
import { newIsRegis } from '@/utils/devices/isRegis';

const useIsRegis = (deviceId: string) => {
  const { data } = useSuspenseQuery(IS_REGIS_DATA, {
    variables: { deviceId },
  });
  return newIsRegis(data.device);
};

export default useIsRegis;

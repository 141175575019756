import { gql } from 'src/__generated__';

const GET_UPDATE_DEVICE_FIELDS = gql(`#graphql
  query DeviceConfiguration_GetUpdateDeviceFields($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
        alias
        originalName
        status: status2
        type: type2
        irrigation {
          id
          name
        }
        organization {
          id
          name
        }
        location {
          latitude
          longitude
        }
      }
    }
  }
`);

export default GET_UPDATE_DEVICE_FIELDS;

import { setDeleteDeviceObservationModal, setUpdateDeviceObservationModal } from '@/slices/modals';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState, type FC } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  observationId: number;
}

interface MenuButton {
  show: boolean;
  label: string;
  onClick: () => void;
}

const MenuItems: FC<Props> = ({ observationId }) => {
  const dispatch = useDispatch();
  const { _ } = useLingui();

  const buttons: MenuButton[] = [
    {
      show: true,
      label: _(msg`Editar`),
      onClick: () => {
        dispatch(
          setUpdateDeviceObservationModal({
            open: true,
            observationId,
          }),
        );
      },
    },
    {
      show: true,
      label: _(msg`Eliminar`),
      onClick: () => {
        dispatch(
          setDeleteDeviceObservationModal({
            open: true,
            observationId,
          }),
        );
      },
    },
  ];

  return buttons.flatMap((button) => {
    if (!button.show) return [];
    return (
      <MenuItem key={button.label} onClick={button.onClick}>
        {button.label}
      </MenuItem>
    );
  });
};

const ObservationMenu: FC<Props> = ({ observationId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItems observationId={observationId} />
      </Menu>
    </>
  );
};

export default ObservationMenu;

import { gql } from '@/__generated__';

const FORMAT_DEVICE_TYPE_GET_DEVICE = gql(`#graphql
  query FormatDeviceType_GetDevice($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        type
        source
        sourceInfo
        sensor
      }
    }
  }
`);

export default FORMAT_DEVICE_TYPE_GET_DEVICE;

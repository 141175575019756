import { useSuspenseQuery } from '@apollo/client';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Avatar } from '@mui/material';
import { FC } from 'react';
import GET_USER_AVATAR from 'src/graphql/querys/getUserAvatar';

interface Props {
  userId: number;
  size?: number;
}

const UserAvatar: FC<Props> = ({ userId, size }) => {
  const { _ } = useLingui();
  const { data } = useSuspenseQuery(GET_USER_AVATAR, {
    variables: {
      userId,
    },
  });

  const { picture, name, lastnames } = data.user;
  const initials = `${name[0] ?? ''}${lastnames[0] ?? ''}`;

  if (picture) {
    return (
      <Avatar
        sx={size ? { width: size, height: size } : undefined}
        src={picture}
        alt={_(msg`Imagen de perfil de usuario`)}
      />
    );
  }
  return (
    <Avatar sx={size ? { width: size, height: size, fontSize: size / 2 } : undefined}>
      {initials}
    </Avatar>
  );
};

export default UserAvatar;

import { gql } from '@apollo/client';

const CLONE_DEVICE = gql`
  mutation CloneDevice($id: String!, $name: String) {
    CloneDeviceOLD(id: $id, name: $name) {
      success
      message
    }
  }
`;

export default CLONE_DEVICE;

import { gql } from '@/__generated__';

const UPDATE_DGA_SCHEDULE = gql(`#graphql
  mutation UpdateDgaSchedule($deviceId: ID!, $input: UpdateDgaScheduleInput!) {
    updateDgaSchedule(deviceId: $deviceId, input: $input) {
      id
      dgaConfiguration {
        id
        code
        rut
        password
        type
        frequency
        hour
        weekday
        day
        enabled
      }
    }
  }
`);

export default UPDATE_DGA_SCHEDULE;

import {
  CreateDgaConfigInput,
  CreateDgaConfigurationMutationVariables,
  DayOfWeek,
  DgaFrequency,
  DgaType,
  UpdateDgaConfigurationMutationVariables,
  UpdateDgaScheduleInput,
  UpdateDgaScheduleMutationVariables,
} from '@/__generated__/graphql';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { z } from 'zod';

const getCreateDgaConfigurationInputSchema = (i18n: I18n) =>
  z.object({
    code: z.string().min(1, "El campo 'code' no puede estar vacío"),
    rut: z
      .string()
      .regex(/^\d{6,8}-[\dK]$/, t(i18n)`El formato del RUT no es válido, ej: 12345678-K`),
    password: z.string().min(1, "El campo 'password' no puede estar vacío"),
    type: z.nativeEnum(DgaType),
  }) satisfies z.ZodType<CreateDgaConfigInput>;

const getUpdateDgaScheduleInputSchema = (i18n: I18n) => {
  const baseSchema = z.object({
    frequency: z.nativeEnum(DgaFrequency),
    enable: z.literal(true),
  });

  const hoursValidation = z.coerce
    .number({ invalid_type_error: t(i18n)`Debes ingresar un número` })
    .int(t(i18n)`El campo 'Hora' debe ser un número entero`)
    .nonnegative()
    .min(0, t(i18n)`El campo 'Hora' debe estar entre 0 y 23`)
    .max(23, t(i18n)`El campo 'Hora' debe estar entre 0 y 23`);

  const dayValidation = z.coerce
    .number()
    .int(t(i18n)`El campo 'Día' debe ser un número entero`)
    .nonnegative()
    .min(1, t(i18n)`El campo 'Día' debe estar entre 1 y 27`)
    .max(27, t(i18n)`El campo 'Día' debe estar entre 1 y 27`);

  const dailySchema = baseSchema.extend({
    frequency: z.literal(DgaFrequency.Daily),
    hour: hoursValidation,
  });

  const weeklySchema = baseSchema.extend({
    frequency: z.literal(DgaFrequency.Weekly),
    hour: hoursValidation,
    weekday: z.nativeEnum(DayOfWeek),
  });

  const monthlySchema = baseSchema.extend({
    frequency: z.literal(DgaFrequency.Monthly),
    hour: hoursValidation,
    day: dayValidation,
  });

  const hourlySchema = baseSchema.extend({
    frequency: z.literal(DgaFrequency.Hourly),
  });

  const enabledSchema = z.discriminatedUnion('frequency', [
    dailySchema,
    weeklySchema,
    monthlySchema,
    hourlySchema,
  ]);

  const disabledSchema = baseSchema.extend({
    enable: z.literal(false),
  });

  return enabledSchema.or(disabledSchema) satisfies z.ZodType<UpdateDgaScheduleInput>;
};

export const getCreateDgaConfigurationMutationSchema = (i18n: I18n) => {
  const createSchema = getCreateDgaConfigurationInputSchema(i18n);
  const scheduleSchema = getUpdateDgaScheduleInputSchema(i18n);
  return z.object({
    deviceId: z.string().min(1, "El campo 'deviceId' no puede estar vacío"),
    input: createSchema,
    scheduleInput: scheduleSchema,
  }) satisfies z.ZodType<CreateDgaConfigurationMutationVariables>;
};

export const getUpdateDgaScheduleMutationSchema = (i18n: I18n) => {
  const scheduleSchema = getUpdateDgaScheduleInputSchema(i18n);
  return z.object({
    deviceId: z.string().min(1, "El campo 'deviceId' no puede estar vacío"),
    input: scheduleSchema,
  }) satisfies z.ZodType<UpdateDgaScheduleMutationVariables>;
};

export const getUpdateDgaConfigurationMutationSchema = (i18n: I18n) => {
  const createSchema = getCreateDgaConfigurationInputSchema(i18n);
  return z.object({
    deviceId: z.string().min(1, "El campo 'deviceId' no puede estar vacío"),
    input: createSchema,
  }) satisfies z.ZodType<UpdateDgaConfigurationMutationVariables>;
};

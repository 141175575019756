import { gql } from '@/__generated__';

const UPDATE_DEVICE_ALIASES = gql(`#graphql
  mutation DeviceConfiguration_UpdateDeviceAliases($deviceId: ID!, $aliases: [UpdateDeviceAliasInput!]!) {
    updateDeviceAliases(deviceId: $deviceId, aliases: $aliases) {
      id
      profile {
        name
        organizationsAliases {
          alias
          organization {
            id
            name
          }
        }
      }
    }
  }
`);

export default UPDATE_DEVICE_ALIASES;

import { useModalStatusMessage } from '@/hooks';
import { useFormatters } from '@/hooks/useFormatters';
import { setDeleteDeviceObservationModal } from '@/slices/modals';
import { useDispatch, useSelector } from '@/store';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import UserAvatar from '@components/UserAvatar';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Suspense, type FC } from 'react';
import { useForm } from 'react-hook-form';
import DELETE_DEVICE_OBSERVATION from '../../graphql/mutations/deleteDeviceObservation';
import GET_OBSERVATION from '../../graphql/queries/getObservationData';

interface Props {
  observationId: number;
  open: boolean;
  onClose: () => void;
}

const Component: FC<Props> = ({ observationId, onClose }) => {
  const { _ } = useLingui();
  const { formatDateTime } = useFormatters();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { data } = useSuspenseQuery(GET_OBSERVATION, {
    variables: {
      deviceObservationId: observationId,
    },
  });
  const [deleteObservation] = useMutation(DELETE_DEVICE_OBSERVATION, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Observación eliminada correctamente`));
      onClose();
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    update(cache) {
      cache.modify({
        id: cache.identify({
          __typename: 'DeviceProfile',
          id: data.deviceObservation.device.id,
        }),
        fields: {
          observations(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  const methods = useForm();

  const onSubmit = () => {
    deleteObservation({
      variables: {
        input: {
          id: observationId,
        },
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <ModalTitle onClose={onClose} title={_(msg`Eliminar observación`)} />
      <DialogContent dividers>
        <DialogContentText>
          <Trans>¿Estás seguro que deseas eliminar la siguiente observación?</Trans>
        </DialogContentText>
        <Card variant="outlined" sx={{ width: '100%', mt: 2 }}>
          <CardHeader
            avatar={<UserAvatar userId={data.deviceObservation.author.id} />}
            title={data.deviceObservation.author.fullName}
            subheader={formatDateTime(data.deviceObservation.date)}
          />
          <CardContent sx={{ pt: 0 }}>
            <Typography variant="body2" color="text.secondary">
              {data.deviceObservation.content}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        submitColor="error"
        submitLabel={_(msg`Eliminar`)}
      />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { _ } = useLingui();
  return (
    <>
      <ModalTitle onClose={onClose} title={_(msg`Actualizar observación`)} />
      <DialogContent dividers>
        <Skeleton width="100%" variant="rounded">
          <TextField fullWidth rows={4} multiline />
        </Skeleton>
      </DialogContent>
      <ModalActions onClose={onClose} />
    </>
  );
};

const DeleteDeviceObservationModal: FC = () => {
  const dispatch = useDispatch();
  const { deleteDeviceObservationModal: modalState } = useSelector((state) => state.modals_store);

  const onClose = () => {
    dispatch(
      setDeleteDeviceObservationModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={modalState.open} onClose={onClose}>
      {modalState.open && (
        <Suspense fallback={<Fallback onClose={onClose} />}>
          <Component
            observationId={modalState.observationId}
            open={modalState.open}
            onClose={onClose}
          />
        </Suspense>
      )}
    </Dialog>
  );
};

export default DeleteDeviceObservationModal;

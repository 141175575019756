import { gql } from '@/__generated__';

const UPDATE_DEVICE_OBSERVATION = gql(`#graphql
  mutation DeviceObservation_UpdateObservation(
    $input: UpdateDeviceObservationInput!
  ) {
    updateDeviceObservation(input: $input) {
      author {
        id
      }
      device {
        id
      }
      date
      content
      id
    }
  }
`);

export default UPDATE_DEVICE_OBSERVATION;

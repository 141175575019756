import { gql } from '@/__generated__';

const GET_OBSERVATION = gql(`#graphql
  query DeviceObservation_GetObservation($deviceObservationId: Int!) {
    deviceObservation(id: $deviceObservationId) {
      id
      date
      content
      author {
        id
        name
        lastnames
        fullName
        picture
      }
      device {
        id
      }
    }
  }
`);

export default GET_OBSERVATION;

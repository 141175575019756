import { useMutation, useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import { Trans, msg } from '@lingui/macro';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Suspense, useEffect, useState, type FC } from 'react';
import { DeviceConfigurationTabProps } from '../../types';
import GET_UPDATE_DEVICE_ACCESS_FIELDS from '../../graphql/queries/getUpdateDeviceAccessFields';
import { FixedSizeList } from 'react-window';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DeviceConfiguration_GetAccessFieldsQuery } from '@/__generated__/graphql';
import Fuse from 'fuse.js';
import ModalActions from '@components/modal/ModalActions';
import { useLingui } from '@lingui/react';
import UPDATE_DEVICE_USERS_ACCESS from '../../graphql/mutations/updateDeviceUsersAccess';
import { useModalStatusMessage } from '@/hooks';

const getDefaultValues = (
  deviceId: string,
  users: DeviceConfiguration_GetAccessFieldsQuery['device']['users'],
) => {
  return {
    deviceId,
    users: users.map((user) => ({
      id: user.user.id,
      hasAccess: user.hasAccess,
    })),
  };
};

type FormValues = {
  deviceId: string;
  users: {
    id: number;
    hasAccess: boolean;
  }[];
};

const Component: FC<DeviceConfigurationTabProps> = ({ deviceId, handleClose, setDirtyForm }) => {
  const { _ } = useLingui();
  const [search, setSearch] = useState('');
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [filteredUserIds, setFilteredUserIds] = useState<
    { dataIndex: number; userId: number }[] | null
  >(null);
  const { data } = useSuspenseQuery(GET_UPDATE_DEVICE_ACCESS_FIELDS, {
    variables: { deviceId },
  });
  const [update] = useMutation(UPDATE_DEVICE_USERS_ACCESS, {
    onCompleted(data) {
      const defaultValues = getDefaultValues(deviceId, data.updateDeviceUsersAccess.users);
      methods.reset(defaultValues);
      openModalSuccessMessage(_(msg`Los usuarios con acceso al dispositivo han sido actualizados`));
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(deviceId, data.device.users),
  });

  useEffect(() => {
    if (search === '') {
      setFilteredUserIds(null);
      return;
    }
    const fuse = new Fuse(data.device.users, {
      keys: ['user.id', 'user.fullName', 'user.email'],
    });

    const filteredUserIds = fuse.search(search, { limit: 5 }).map(({ item }) => ({
      dataIndex: data.device.users.findIndex(({ user }) => user.id === item.user.id),
      userId: item.user.id,
    }));
    setFilteredUserIds(filteredUserIds);
  }, [search, data]);

  const formUsers = methods.watch('users');
  const selectedUsers = formUsers.filter((user) => user.hasAccess);

  const onSelectAll = () => {
    methods.setValue(
      'users',
      formUsers.map((user) => ({
        ...user,
        hasAccess: true,
      })),
      {
        shouldDirty: true,
      },
    );
  };

  const onDeselectAll = () => {
    methods.setValue(
      'users',
      formUsers.map((user) => ({
        ...user,
        hasAccess: false,
      })),
      {
        shouldDirty: true,
      },
    );
  };

  useEffect(
    () => setDirtyForm(methods.formState.isDirty),
    [methods.formState.isDirty, setDirtyForm],
  );

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    update({ variables: { deviceId, users: data.users } });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent
        sx={{
          maxWidth: 'md',
          minHeight: 500,
          margin: 'auto',
        }}
      >
        <Typography variant="h6" mb={2}>
          <Trans>Usuarios con acceso al dispositivo</Trans>
        </Typography>
        <Box
          mb={1}
          display="grid"
          alignItems="center"
          gridTemplateColumns="1fr auto auto 1fr"
          gap={1}
        >
          <TextField
            label="Buscar"
            fullWidth
            size="small"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <Button
            variant="outlined"
            color="info"
            disabled={selectedUsers.length === data.device.users.length}
            onClick={onSelectAll}
          >
            <Trans>Seleccionar todos</Trans>
          </Button>
          <Button
            variant="outlined"
            color="info"
            disabled={selectedUsers.length === 0}
            onClick={onDeselectAll}
          >
            <Trans>Deseleccionar todos</Trans>
          </Button>
          <Typography justifySelf="right">
            <Trans>
              <b>{selectedUsers.length}</b> usuarios seleccionados de{' '}
              <b>{data.device.users.length}</b>
            </Trans>
          </Typography>
        </Box>
        <Box border={1} borderColor="divider" borderRadius={1}>
          <FixedSizeList
            height={400}
            width="100%"
            itemSize={72}
            itemCount={filteredUserIds?.length ?? data.device.users.length}
            itemKey={(index) => data.device.users[index].user.id}
          >
            {({ style, index }) => {
              const { user } = filteredUserIds
                ? data.device.users[filteredUserIds[index].dataIndex]
                : data.device.users[index];
              return (
                <Controller
                  name={`users.${index}.hasAccess`}
                  control={methods.control}
                  render={({ field, fieldState }) => {
                    return (
                      <ListItem
                        disablePadding
                        style={style}
                        key={user.id}
                        sx={{
                          borderBottom: index !== data.device.users.length - 1 ? '1px solid' : null,
                          borderColor: 'divider',
                        }}
                      >
                        <ListItemButton
                          onClick={() => {
                            field.onChange(!field.value);
                          }}
                        >
                          <ListItemText
                            primary={user.id + ' - ' + user.fullName}
                            secondary={user.email}
                          />
                          {fieldState.isDirty && (
                            <Typography fontSize="small" fontStyle="italic" color="text.secondary">
                              <Trans>(editado)</Trans>
                            </Typography>
                          )}

                          <ListItemIcon>
                            <Checkbox edge="end" checked={field.value} tabIndex={-1} />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    );
                  }}
                />
              );
            }}
          </FixedSizeList>
        </Box>
      </DialogContent>
      <Box
        sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <ModalActions
          onClose={handleClose}
          onSubmit="submit"
          onResetForm={() => methods.reset()}
          submitLoading={methods.formState.isSubmitting}
          dirtyForm={methods.formState.isDirty}
        />
      </Box>
    </form>
  );
};

const AccessTab: FC<DeviceConfigurationTabProps> = (props) => {
  return (
    <ApolloErrorBoundary>
      <Suspense
        fallback={
          <DialogContent>
            <Skeleton height={500} variant="rounded" />
          </DialogContent>
        }
      >
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default AccessTab;

import { useModalStatusMessage } from '@/hooks';
import { setCreateDeviceObservationModal } from '@/slices/modals';
import { useDispatch, useSelector } from '@/store';
import { useMutation } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { zodResolver } from '@hookform/resolvers/zod';
import { I18n } from '@lingui/core';
import { msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Dialog, DialogContent, TextField } from '@mui/material';
import { type FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import CREATE_DEVICE_OBSERVATION from '../../graphql/mutations/createDeviceObservation';

interface Props {
  deviceId: string;
  open: boolean;
  onClose: () => void;
}

const schema = (i18n: I18n) =>
  z.object({
    content: z.string().min(2, t(i18n)`El contenido debe tener al menos 2 caracteres`),
  });

type FormValues = z.infer<ReturnType<typeof schema>>;

const Component: FC<Props> = ({ deviceId, open, onClose }) => {
  const { _, i18n } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [createObservation] = useMutation(CREATE_DEVICE_OBSERVATION, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Observación creada correctamente`));
      onClose();
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    update(cache) {
      cache.modify({
        id: cache.identify({
          __typename: 'DeviceProfile',
          id: deviceId,
        }),
        fields: {
          observations(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      content: '',
    },
    resolver: zodResolver(schema(i18n)),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    createObservation({
      variables: {
        input: {
          deviceId,
          content: data.content,
        },
      },
    });
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalTitle onClose={onClose} title={_(msg`Crear observación`)} />
        <DialogContent dividers>
          <Controller
            name="content"
            control={methods.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={_(msg`Contenido`)}
                fullWidth
                rows={4}
                multiline
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </DialogContent>
        <ModalActions onClose={onClose} onSubmit="submit" />
      </form>
    </Dialog>
  );
};

const CreateDeviceObservationModal: FC = () => {
  const dispatch = useDispatch();
  const { createDeviceObservationModal: modalState } = useSelector((state) => state.modals_store);

  const onClose = () => {
    dispatch(
      setCreateDeviceObservationModal({
        open: false,
      }),
    );
  };

  return (
    modalState.open && (
      <Component deviceId={modalState.deviceId} open={modalState.open} onClose={onClose} />
    )
  );
};

export default CreateDeviceObservationModal;

import { UpdateDeviceFlowLimitsInput } from '@/__generated__/graphql';
import { setSnackbar } from '@/slices/components';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import NumericTextField from '@components/NumericTextField';
import Loading from '@components/distribution/Loading';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';
import { Suspense, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import UPDATE_DEVICE_FLOW_LIMITS from '../graphql/mutations/updateDeviceFlowLimits';
import GET_DEVICE_FLOW_LIMITS from '../graphql/queries/getRadarCurves';
import { schema } from '../utils/validationSchema';

interface Props {
  deviceId: string;
  handleClose: () => void;
  setDirtyForm: (value: boolean) => void;
}

const FlowLimitsTab: FC<Props> = (props) => {
  return (
    <ApolloErrorBoundary>
      <Suspense fallback={<Loading />}>
        <FormComponent {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

type FormValues = UpdateDeviceFlowLimitsInput;

const FormComponent: FC<Props> = ({ deviceId, handleClose }) => {
  const { _ } = useLingui();
  const { data } = useSuspenseQuery(GET_DEVICE_FLOW_LIMITS, { variables: { deviceId } });

  const dispatch = useDispatch();

  const defaultValues: FormValues = {
    deviceId,
    state: {
      isDry: data?.deviceDataConfiguration.state?.isDry ?? false,
    },
    flowLimits: {
      min: data?.deviceDataConfiguration.flowLimits?.min ?? null,
      max: data?.deviceDataConfiguration.flowLimits?.max ?? null,
    },
  };

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver<FormValues>(schema),
  });

  const [update, { loading: updateLoading }] = useMutation(UPDATE_DEVICE_FLOW_LIMITS, {
    onCompleted: ({ updateDeviceFlowLimits }) => {
      methods.reset({
        deviceId: updateDeviceFlowLimits.deviceId,
        state: {
          isDry: updateDeviceFlowLimits.state?.isDry ?? false,
        },
        flowLimits: {
          min: updateDeviceFlowLimits.flowLimits?.min ?? null,
          max: updateDeviceFlowLimits.flowLimits?.max ?? null,
        },
      });
      dispatch(
        setSnackbar({
          open: true,
          message: _(msg`Dato actualizado correctamente`),
          severity: 'success',
        }),
      );
    },
    onError: (errors) => {
      dispatch(
        setSnackbar({
          open: true,
          message: `${_(msg`Error al actualizar el dato`)}: ${errors.message}`,
          severity: 'error',
        }),
      );
    },
  });

  const { isDirty, isSubmitting } = methods.formState;

  const submitHandler = async (formData: FormValues) => {
    update({ variables: { input: formData } });
  };

  return (
    <form onSubmit={methods.handleSubmit(submitHandler)}>
      <DialogContent
        sx={{
          maxWidth: 'md',
          minHeight: 500,
          margin: 'auto',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <Typography variant="h5" mb={2}>
          <Trans>Límites de Caudal</Trans>
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" fontSize="small" gap={2} mb={3}>
          <Controller
            name="flowLimits.min"
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <NumericTextField
                {...field}
                size="small"
                label={_(msg`Caudal mínimo`)}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="flowLimits.max"
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <NumericTextField
                {...field}
                size="small"
                label={_(msg`Caudal máximo`)}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="state.isDry"
            control={methods.control}
            render={({ field: { value, ref, ...rest } }) => (
              <FormGroup>
                <FormControlLabel
                  {...rest}
                  checked={value}
                  inputRef={ref}
                  control={<Checkbox />}
                  label={_(msg`Activar canal seco`)}
                />
                <FormHelperText sx={{ color: '#FF9E42' }}>
                  <Trans>
                    <b>¡PRECAUCIÓN!</b> Activar esto causará que los datos del dispositivo se
                    guarden como 0.
                  </Trans>
                </FormHelperText>
              </FormGroup>
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ maxWidth: 'md', margin: 'auto', py: 2 }}>
        <Button
          disabled={!isDirty}
          color="info"
          variant="outlined"
          onClick={() => methods.reset(defaultValues)}
        >
          <Trans>Restablecer Formulario</Trans>
        </Button>
        <Box flexGrow={1} />
        <Button color="info" variant="outlined" onClick={() => handleClose()}>
          <Trans>Cerrar</Trans>
        </Button>
        <LoadingButton
          disabled={!isDirty}
          loading={isSubmitting || updateLoading}
          type="submit"
          variant="contained"
        >
          <Trans>Guardar</Trans>
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default FlowLimitsTab;

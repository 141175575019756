import { type FC } from 'react';
import CreateDeviceObservationModal from './modals/CreateObservationModal';
import UpdateDeviceObservationModal from './modals/UpdateObservationModal';
import DeleteDeviceObservationModal from './modals/DeleteObservationModal';

const DeviceObservationsModals: FC = () => {
  return (
    <>
      <CreateDeviceObservationModal />
      <UpdateDeviceObservationModal />
      <DeleteDeviceObservationModal />
    </>
  );
};

export default DeviceObservationsModals;

import { type ButtonProps, Dialog, DialogContent, DialogContentText } from '@mui/material';
import { type FC } from 'react';
import ModalTitle from './ModalTitle';
import ModalActions from './ModalActions';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
  submitButton?: ButtonProps & { label?: string; loading?: boolean };
  closeButton?: ButtonProps & { label?: string; loading?: boolean };
}

const ConfirmationModal: FC<Props> = ({
  open,
  onClose,
  title,
  content,
  submitButton,
  closeButton,
}) => {
  const { _ } = useLingui();
  const defaultTitle = _(msg`Confirmación`);
  const defaultContent = _(msg`¿Estás seguro de que deseas continuar?`);
  const defaultSubmitButtonLabel = _(msg`Confirmar`);
  const defaultCloseButtonLabel = _(msg`Cancelar`);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" disablePortal>
      <ModalTitle title={title ?? defaultTitle} onClose={onClose} />
      <DialogContent dividers>
        <DialogContentText>{content ?? defaultContent}</DialogContentText>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        submitButton={{
          ...submitButton,
          label: submitButton?.label ?? defaultSubmitButtonLabel,
        }}
        closeButton={{
          label: closeButton?.label ?? defaultCloseButtonLabel,
        }}
      />
    </Dialog>
  );
};

export default ConfirmationModal;

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState, type FC } from 'react';

type Panel = {
  key?: string;
  label: string;
  component: JSX.Element | string;
};

interface Props {
  /**
   * Array of panels to render.
   // * The first panel will be the default selected one.
   */
  panels: Panel[];
  // panels: [Panel, ...Panel[]];
  /**
   * Function to execute when the user changes the tab.
   * This function will be executed before the tab changes.
   * @default undefined
   **/
  changeTabMiddleware?: (changeTabAction: () => void) => void;
  value?: string;
  setValue?: (newValue: string) => void;
}

const ModalTabs: FC<Props> = ({
  panels,
  changeTabMiddleware,
  value: valueControlled,
  setValue: setValueControlled,
}) => {
  const [value, setValue] = useState<string>(panels[0]?.key ?? panels[0]?.label);

  const valueFinal = valueControlled == null ? value : valueControlled;
  const setValueFinal = setValueControlled == null ? setValue : setValueControlled;

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    if (changeTabMiddleware) {
      changeTabMiddleware(() => setValueFinal(newValue));
    } else {
      setValueFinal(newValue);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'visible',
      }}
    >
      <TabContext value={valueFinal}>
        <Box
          sx={{
            mx: -1,
            backgroundColor: '#343c47',
            '& .MuiTabs-indicator': {
              height: '100%',
              backgroundColor: 'background.paper',
              borderTop: '4px solid #eee',
              borderLeft: '1px solid #aaa',
              borderRight: '1px solid #aaa',
              zIndex: 1,
            },
          }}
        >
          <TabList variant="fullWidth" onChange={handleChange} aria-label="Modal tabs">
            {panels.map(({ key, label }, index) => (
              <Tab
                key={index}
                label={
                  <Typography
                    zIndex={2}
                    variant="h6"
                    fontSize="16px !important"
                    textTransform="unset"
                  >
                    {label}
                  </Typography>
                }
                value={key ?? label}
                sx={{
                  flexGrow: 1,
                  maxWidth: 'unset',
                  textTransform: 'unset',
                  borderBottom: '1px solid #aaa',
                  '&:not(:last-child)': {
                    borderRight: '1px solid #cccccc22',
                  },
                  '&.Mui-selected': {
                    color: 'white',
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        {panels.map(({ key, label, component }) => (
          <TabPanel sx={{ p: 0, flexGrow: 1 }} key={key ?? label} value={key ?? label}>
            {component}
          </TabPanel>

          // {/* <Box display={value !== label ? 'none' : 'unset'} key={label}> */}
          // {/*   {component} */}
          // {/* </Box> */}
        ))}
      </TabContext>
    </Box>
  );
};

export default ModalTabs;

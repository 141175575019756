import { gql } from '@/__generated__';

const GET_MODAL_TITLE = gql(`#graphql
  query DeviceConfiguration_GetModalTitle($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
        organization {
          name
          id
        }
      }
    }
  }
`);

export default GET_MODAL_TITLE;

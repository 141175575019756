import { useDispatch } from 'react-redux';
import { useSelector } from '@/store';
import { setDeprecatedCreateMeasurementModal } from 'src/slices/modals';
import CreateMeasurement from './CreateMeasurement';

const CreateMeasurementAdapterModal = () => {
  const dispatch = useDispatch();
  const { deprecatedCreateMeasurementModal: modalState } = useSelector(
    (state) => state.modals_store,
  );

  const onClose = () => {
    dispatch(
      setDeprecatedCreateMeasurementModal({
        open: false,
      }),
    );
  };

  if (!modalState.open) return null;

  return (
    <CreateMeasurement
      show={modalState.open}
      handleClose={onClose}
      device={{
        id: modalState.deviceId,
        name: modalState.deviceId,
      }}
    />
  );
};

export default CreateMeasurementAdapterModal;

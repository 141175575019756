import { gql } from '@/__generated__';

const CLONE_DEVICE = gql(`#graphql
  mutation DeviceConfiguration_CloneDevice($deviceId: ID!, $name: String!) {
    cloneDevice(deviceId: $deviceId, name: $name) {
      id
      profile {
        id
        name
      }
    }
  }
`);

export default CLONE_DEVICE;

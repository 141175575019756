import { gql } from '@/__generated__';

const GET_UPDATE_DEVICE_ACCESS_FIELDS = gql(`#graphql
  query DeviceConfiguration_GetAccessFields($deviceId: ID!) {
    device(input: {
      id: $deviceId
    }) {
      id
      users(input:{
        ignoreAccess: true
      }) {
        hasAccess
        user {
          id
          email
          fullName
        }
      }
    }
  }
`);

export default GET_UPDATE_DEVICE_ACCESS_FIELDS;

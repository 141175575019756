import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ModalRequiredDevice =
  | {
      open: true;
      deviceId: string;
    }
  | {
      open: false;
    };

interface ModalsState {
  updateDeviceAlertsModal: {
    open: boolean;
    deviceId?: string;
  };
  createDeviceObservationModal: ModalRequiredDevice;
  updateDeviceObservationModal:
    | {
        open: true;
        observationId: number;
      }
    | {
        open: false;
      };
  deleteDeviceObservationModal:
    | {
        open: true;
        observationId: number;
      }
    | {
        open: false;
      };
  updateDeviceDataConfigurationModal: ModalRequiredDevice;
  /** @deprecated */
  deprecatedCreateMeasurementModal: ModalRequiredDevice;
  /** @deprecated */
  deprecatedMeasurementsTableModal: ModalRequiredDevice;
}

const initialState: ModalsState = {
  updateDeviceAlertsModal: {
    open: false,
  },
  createDeviceObservationModal: {
    open: false,
  },
  updateDeviceObservationModal: {
    open: false,
  },
  deleteDeviceObservationModal: {
    open: false,
  },
  updateDeviceDataConfigurationModal: {
    open: false,
  },
  deprecatedCreateMeasurementModal: {
    open: false,
  },
  deprecatedMeasurementsTableModal: {
    open: false,
  },
};

const { reducer, actions } = createSlice({
  name: 'modals_store',
  initialState,
  reducers: {
    setUpdateDeviceAlertsModal(
      state,
      action: PayloadAction<ModalsState['updateDeviceAlertsModal']>,
    ) {
      state.updateDeviceAlertsModal = action.payload;
    },
    setCreateDeviceObservationModal(
      state,
      action: PayloadAction<ModalsState['createDeviceObservationModal']>,
    ) {
      state.createDeviceObservationModal = action.payload;
    },
    setUpdateDeviceObservationModal(
      state,
      action: PayloadAction<ModalsState['updateDeviceObservationModal']>,
    ) {
      state.updateDeviceObservationModal = action.payload;
    },
    setDeleteDeviceObservationModal(
      state,
      action: PayloadAction<ModalsState['deleteDeviceObservationModal']>,
    ) {
      state.deleteDeviceObservationModal = action.payload;
    },
    setUpdateDeviceDataConfigurationModal: (
      state,
      action: PayloadAction<ModalsState['updateDeviceDataConfigurationModal']>,
    ) => {
      state.updateDeviceDataConfigurationModal = action.payload;
    },
    setDeprecatedCreateMeasurementModal: (
      state,
      action: PayloadAction<ModalsState['deprecatedCreateMeasurementModal']>,
    ) => {
      state.deprecatedCreateMeasurementModal = action.payload;
    },
    setDeprecatedMeasurementsTableModal: (
      state,
      action: PayloadAction<ModalsState['deprecatedMeasurementsTableModal']>,
    ) => {
      state.deprecatedMeasurementsTableModal = action.payload;
    },
  },
});

export default reducer;
export const {
  setUpdateDeviceAlertsModal,
  setCreateDeviceObservationModal,
  setUpdateDeviceObservationModal,
  setDeleteDeviceObservationModal,
  setUpdateDeviceDataConfigurationModal,
  setDeprecatedMeasurementsTableModal,
  setDeprecatedCreateMeasurementModal,
} = actions;

import { gql } from '@/__generated__';

const GET_CLONE_MODAL_FIELDS = gql(`#graphql
  query DeviceConfiguration_GetCloneModalFields($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
      }
    }
  }
`);

export default GET_CLONE_MODAL_FIELDS;

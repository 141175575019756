import { Grid, TextField } from '@mui/material';
import { DateTimePicker } from '@features/dga/components/modals/ticketConfiguration';
import { InputValues, InputValuesErrors } from '@features/dga/types/';
import ActionButtons from 'src/components/ActionButtons';

interface Props {
  values: InputValues;
  inputValueErrors: InputValuesErrors;
  setValues: (values: InputValues) => void;
  handleCreate: () => void;
  handleInternalClose: (refetch: boolean) => void;
}

const FieldsContent = ({
  values,
  setValues,
  inputValueErrors,
  handleInternalClose,
  handleCreate,
}: Props) => {
  const handleTimePickerChange = (value) => {
    setValues({ ...values, ticketDate: value });
  };
  const handleTicketIDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, ticketID: e.target.value });
  };
  const handleCloseWithoutLoading = () => {
    handleInternalClose(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
            label="Ticket ID"
            fullWidth
            value={values.ticketID}
            onChange={handleTicketIDChange}
            error={inputValueErrors.ticketID.isOnError}
            helperText={inputValueErrors.ticketID.message}
          />
      </Grid>
      <Grid item xs={12}>
        <DateTimePicker
            dateTime={values.ticketDate}
            setDateTime={handleTimePickerChange}
          />
      </Grid>
      <Grid item xs={12}>
        <ActionButtons
          closeFunction={handleCloseWithoutLoading}
          actionFunction={handleCreate}
          actionButtonText="Guardar"
        />
      </Grid>
    </Grid>
  );
};

export default FieldsContent;

import { useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import { DialogContent, Skeleton, Typography } from '@mui/material';
import { Suspense, type FC } from 'react';
import GET_DEVICE_SENSOR from '../graphql/queries/getDeviceSensor';
import { Trans } from '@lingui/macro';
import LevelCalibrationForm from './LevelCalibrationForm';
import PositionFromAngleCalibrationForm from './PositionFromAngleCalibrationForm';
import LGCalibrationDisplay from './LGCalibrationDisplay';

export interface DeviceDataConfigurationModalProps {
  deviceId: string;
  handleClose: () => void;
  setDirtyForm: (value: boolean) => void;
}

const CalibrationTab: FC<DeviceDataConfigurationModalProps> = (props) => {
  const { data } = useSuspenseQuery(GET_DEVICE_SENSOR, {
    variables: { deviceId: props.deviceId },
  });

  if (!data.device.profile.sensor)
    return (
      <Typography color="error">
        <Trans>Error: el dispositivo {props.deviceId} no posee sensor</Trans>
      </Typography>
    );

  const showLevelCalibration = data.device.profile.sensor?.includes('L') ?? false;
  const showPositionFromAngleCalibration = data.device.profile.sensor?.includes('G') ?? false;

  return (
    <ApolloErrorBoundary>
      <Suspense
        fallback={
          <DialogContent>
            <Skeleton height={500} variant="rounded" />
          </DialogContent>
        }
      >
        {showLevelCalibration && !showPositionFromAngleCalibration && (
          <LevelCalibrationForm {...props} />
        )}
        {showPositionFromAngleCalibration && !showLevelCalibration && (
          <PositionFromAngleCalibrationForm {...props} />
        )}
        {showLevelCalibration && showPositionFromAngleCalibration && (
          <LGCalibrationDisplay {...props} />
        )}
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default CalibrationTab;

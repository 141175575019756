import { gql } from '@/__generated__';

const CREATE_DGA_CONFIGURATION = gql(`#graphql
  mutation CreateDgaConfiguration(
    $deviceId: ID!,
    $input: CreateDgaConfigInput!,
    $scheduleInput: UpdateDgaScheduleInput!
  ) {
    createDgaConfiguration(deviceId: $deviceId, input: $input, scheduleInput: $scheduleInput) {
      id
      dgaConfiguration {
        id
        code
        rut
        password
        type
        frequency
        hour
        weekday
        day
        enabled
      }
    }
  }
`);

export default CREATE_DGA_CONFIGURATION;
